import React, { useEffect, useState } from "react";
import {
  deleteApi,
  getApi,
  postApi,
  putApi,
} from "../../utilits/helpers/apiService";
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Subjects = () => {
  const { checkAuth } = CheckLogin();
  const [errors, setErrors] = useState({});
  const [subjects, setSubjects] = useState([]);
  const [subjectsId, setSubjectsId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [subjectsFormData, setSubjectsFormData] = useState({
    sub_name: "",
    sub_code: "",
  });

  const fetchSubjects = async () => {
    try {
      const subjects = await getApi("/subject/get");
      console.log(subjects)
      setSubjects(subjects.response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchSubjects();
    checkAuth();
  }, [])

  const postClassData = async (data) => {
    try {
      await postApi("/subject/create", data);
      // console.log(postClassData);
      setSubjectsFormData({
        sub_name: "",
        sub_code: "",
      });
      fetchSubjects();
      setIsAdd(false);
      let saveClassSuccessMessage = "New Subject created successfully";
      toast.success(saveClassSuccessMessage);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.saveClassData) {
        // Extracting the specific message from the server response
        if (error.saveClassData.data && error.saveClassData.data.message) {
          errorMessage = error.saveClassData.data.message;
        } else if (typeof error.saveClassData.data === "string") {
          errorMessage = error.saveClassData.data;
        } else {
          errorMessage = `Error: ${error.saveClassData.status} - ${error.saveClassData.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  const updateClassData = async (data, id) => {
    try {
      await putApi(`/subject/update/${id}`, data);
      setSubjectsId("");
      setSubjectsFormData({
        sub_name: "",
        sub_code: "",
      });
      setIsAdd(false);
      fetchSubjects();
      let successMessage = "Subject Data updated successfully";
      toast.success(successMessage);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setSubjectsFormData({
      ...subjectsFormData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const submitsubjectsForm = (e) => {
    e.preventDefault();
    // console.log('Form Data:', subjectsFormData);
    const validationErrors = validate(subjectsFormData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (subjectsId) {
        updateClassData(subjectsFormData, subjectsId);
      } else {
        postClassData(subjectsFormData);
      }
    }
  };

  const validate = (formData) => {
    const errors = {};
    if (!formData.sub_name) {
      errors.sub_name = "Subject Name is required";
    }
    if (!formData.sub_code) {
      errors.sub_code = "Subject Code is required";
    }
    return errors;
  };

  const getSubjectData = async (id) => {
    try {
      setIsAdd(true);
      const getsubjects = subjects.find((classData) => classData._id === id);
      setSubjectsId(id);
      setSubjectsFormData({
        sub_name: getsubjects.sub_name || "",
        sub_code: getsubjects.sub_code || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteSubjectData = async (id) => {
    const willDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Subject!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });
    if (willDelete.isConfirmed) {
      try {
        const deleteSubject = await deleteApi(`/subject/delete/${id}`);
        let successMessage = "Subject Data has Been Deleted successfully";
        toast.success(successMessage);
        fetchSubjects();
        console.log(deleteSubject);
      } catch (error) {
        console.log("Error caught:", error);
        let errorMessage = "An error occurred";
        if (error.response) {
          // Extracting the specific message from the server response
          if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (typeof error.response.data === "string") {
            errorMessage = error.response.data;
          } else {
            errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
          }
        } else if (error.request) {
          errorMessage = "No response received from server";
        } else {
          errorMessage = error.message;
        }

        toast.error(errorMessage);
      }
    } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Data is safe :)", "info");
    }
  };


  return (
    <>
      <section>
        <div className="container">
          <ToastContainer />
          <div className="row">
            {!isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row ">
                      <div className="col-8 stripBg p-3">
                        <h5>ALL subjects</h5>
                      </div>
                      <div className="col-4 stripBg p-3 text-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                      </div>
                      <div className="col-12 mt-3">
                        <input type="search" placeholder="Search Class" />
                      </div>
                      <div className="col-12 py-3 px-0">
                        <div className="body p-0">
                          <div className="table-responsive">
                            <table className="table table-hover m-0">
                              <thead>
                                <tr className="text-center tableHead">
                                  <th>S.No</th>
                                  <th>Class</th>
                                  <th>Section</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {subjects ? (
                                  subjects.map((sub, index) => (
                                    <tr>
                                      <td className="text-center">
                                        {index + 1}
                                      </td>
                                      <td className="text-center">
                                        {sub.sub_name}
                                      </td>
                                      <td className="text-center text-uppercase">
                                        {sub.sub_code}
                                      </td>
                                      <td className="text-center">
                                        <i
                                          className="fa-solid fa-pen text-success"
                                          onClick={() =>
                                            getSubjectData(sub._id)
                                          }
                                        ></i>
                                        <i
                                          className="fa-solid fa-trash text-danger ms-3"
                                          onClick={() =>
                                            deleteSubjectData(sub._id)
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="4"
                                      className="text-center"
                                    >
                                      No subjects found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row stripBg">
                      <div className="col-8  p-3">
                        <h5>subjects INFORMATION</h5>
                      </div>
                      <div className="col-4  p-3 text-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                      </div>
                    </div>
                    <form
                      name="subjectsForm"
                      id="subjectsForm"
                      onSubmit={submitsubjectsForm}
                    >
                      <div className="row px-2">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="sub_name" className="form-label">
                              Subject Name<span className="text-danger">*</span>
                            </label>

                            <input type="text" name="sub_name" id="sub_name" placeholder="Subject Name" autoComplete="off" value={subjectsFormData.sub_name} onChange={handleClassChange} />
                            {errors.sub_name && (
                              <p className="text-danger">
                                {errors.sub_name}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label
                              htmlFor="sub_code"
                              className="form-label"
                            >
                              Subject Code
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="sub_code"
                              id="sub_code"
                              placeholder="Subject Code"
                              value={subjectsFormData.sub_code}
                              onChange={handleClassChange}
                            />
                            {errors.sub_code && (
                              <p className="text-danger">
                                {errors.sub_code}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 my-5 text-center">
                          <button type="submit" className="allBtns w-100">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </section>
    </>
  )
}

export default Subjects
