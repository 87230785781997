import React, { useEffect, useState } from "react";
import {
    deleteApi,
    getApi,
    postApi,
    putApi,
} from "../utilits/helpers/apiService";
import { CheckLogin } from '../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

const PettyCash = () => {
    const { checkAuth } = CheckLogin();
    const [errors, setErrors] = useState({});
    const [pettyCash, setPettyCash] = useState([]);
    const [pettyCashId, setPettyCashId] = useState("");
    const [isAdd, setIsAdd] = useState(false);
    const [pettyCashFormData, setPettyCashFormData] = useState({
        amount: "",
        description: "",
        expence_date: "",
    });

    const fetchPettyCashData = async () => {
        try {
            const res = await getApi("/petty-cash/get");
            console.log(res);
            setPettyCash(res.response);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchPettyCashData();
        checkAuth();
    }, [])

    const postPettyCashData = async (data) => {
        try {
            await postApi("/petty-cash", data);
            setPettyCashFormData({
                amount: "",
                description: "",
                expence_date: "",
            });
            fetchPettyCashData();
            setIsAdd(false);
            let savePettyCashSuccessMsg = "Petty Cash Data created successfully";
            toast.success(savePettyCashSuccessMsg);
        } catch (error) {
            console.log("Error caught:", error);
            let errorMessage = "An error occurred";
            if (error.saveClassData) {
                // Extracting the specific message from the server response
                if (error.saveClassData.data && error.saveClassData.data.message) {
                    errorMessage = error.saveClassData.data.message;
                } else if (typeof error.saveClassData.data === "string") {
                    errorMessage = error.saveClassData.data;
                } else {
                    errorMessage = `Error: ${error.saveClassData.status} - ${error.saveClassData.statusText}`;
                }
            } else if (error.request) {
                errorMessage = "No response received from server";
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    };

    const updatePettyCashData = async (data, id) => {
        // console.log(data, id);
        try {
            await putApi(`/petty-cash/update/${id}`, data);
            setPettyCashId("");
            setPettyCashFormData({
                amount: "",
                description: "",
                expence_date: "",
            });
            setIsAdd(false);
            fetchPettyCashData();
            let successMessage = "Petty Cash Data updated successfully";
            toast.success(successMessage);
        } catch (error) {
            console.log("Error caught:", error);
            let errorMessage = "An error occurred";
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === "string") {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = "No response received from server";
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    };

    const handlePettyCashChange = (e) => {
        const { name, value } = e.target;
        setPettyCashFormData({
            ...pettyCashFormData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: "",
        });
    };
    const submitPettyCashForm = (e) => {
        e.preventDefault();
        console.log('Form Data:', pettyCashFormData);
        const validationErrors = validate(pettyCashFormData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            if (pettyCashId) {
                updatePettyCashData(pettyCashFormData, pettyCashId);
            } else {
                postPettyCashData(pettyCashFormData);
            }
        }
    };

    const validate = (formData) => {
        const errors = {};
        if (!formData.amount) {
            errors.amount = "Amount is required";
        }
        if (!formData.description) {
            errors.description = "Description is required";
        }
        if (!formData.expence_date) {
            errors.expence_date = "Expence Date is required";
        }
        return errors;
    };

    const getpettyCashData = async (id) => {
        try {
            setIsAdd(true);
            const getPettyCash = pettyCash.find((pettyCashData) => pettyCashData._id === id);
            const formattedJoiningDate = getPettyCash.expence_date ? new Date(getPettyCash.expence_date).toISOString().split('T')[0] : '';
            setPettyCashId(id);
            setPettyCashFormData({
                amount: getPettyCash.amount || "",
                description: getPettyCash.description || "",
                expence_date: formattedJoiningDate,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const deletePettyCashData = async (id) => {
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this account!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            reverseButtons: true,
        });
        if (willDelete.isConfirmed) {
            try {
                const deleteClass = await deleteApi(`/petty-cash/delete/${id}`);
                let successMessage = "Petty Cash Data has Been Deleted successfully";
                toast.success(successMessage);
                fetchPettyCashData();
                console.log(deleteClass);
            } catch (error) {
                console.log("Error caught:", error);
                let errorMessage = "An error occurred";
                if (error.response) {
                    // Extracting the specific message from the server response
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (typeof error.response.data === "string") {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                    }
                } else if (error.request) {
                    errorMessage = "No response received from server";
                } else {
                    errorMessage = error.message;
                }

                toast.error(errorMessage);
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Data is safe :)", "info");
        }
    };


    const navigate = useNavigate();
    const handleDownloadClick = (item) => {
        navigate('/petty-cash-print-screen', { state: { pettyCashData: item } }); 
    };

    return (
        <>
            <section>
                <div className="container">
                    <ToastContainer />
                    <div className="row">
                        {!isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                                                <h5>Petty Cash</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                                <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <input type="search" placeholder="Search Class" />
                                            </div>
                                            <div className="col-12 py-3 px-0">
                                                <div className="body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover m-0">
                                                            <thead>
                                                                <tr className="text-center tableHead">
                                                                    <th>S.No</th>
                                                                    <th>Amount</th>
                                                                    <th colSpan={2}>Description</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pettyCash ? (
                                                                    pettyCash.map((pettyCs, index) => (
                                                                        <tr>
                                                                            <td className="text-center">
                                                                                {index + 1}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                {pettyCs.amount}
                                                                            </td>
                                                                            <td className="text-center" colSpan={2}>
                                                                                {pettyCs.description}
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <i
                                                                                    className="fa-solid fa-pen text-success"
                                                                                    onClick={() =>
                                                                                        getpettyCashData(pettyCs._id)
                                                                                    }
                                                                                ></i>
                                                                                <i
                                                                                    className="fa-solid fa-trash text-danger ms-3"
                                                                                    onClick={() =>
                                                                                        deletePettyCashData(pettyCs._id)
                                                                                    }
                                                                                ></i>

                                                                                <i className="fa fa-download ms-3 text-warning" onClick={() => handleDownloadClick(pettyCs)}></i>

                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr>
                                                                        <td
                                                                            colSpan=""
                                                                            className="text-center"
                                                                        >
                                                                            No Petty Cash Found
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row stripBg">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12  p-3">
                                                <h5>PETTY CASH INFORMATION</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                                <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                                            </div>
                                        </div>
                                        <form
                                            name="pettyCashForm"
                                            id="pettyCashForm"
                                            onSubmit={submitPettyCashForm}
                                        >
                                            <div className="row px-2">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="amount" className="form-label" > Amount <span className="text-danger">*</span> </label>
                                                        <input autoComplete="off" type="number" className="" name="amount" id="amount" placeholder="Amount" value={pettyCashFormData.amount} onChange={handlePettyCashChange} />
                                                        {errors.amount && (<p className="text-danger"> {errors.amount} </p>)}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="expence_date" className="form-label" > Expense Date <span className="text-danger">*</span> </label>
                                                        <input autoComplete="off" type="date" className="expence_date" name="expence_date" id="expence_date" placeholder="Expense Date" value={pettyCashFormData.expence_date} onChange={handlePettyCashChange} />
                                                        {errors.expence_date && (<p className="text-danger"> {errors.expence_date} </p>)}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="description" className="form-label" > Description <span className="text-danger">*</span> </label>
                                                        <textarea name="description" id="description" value={pettyCashFormData.description} onChange={handlePettyCashChange}></textarea>
                                                        {errors.description && (<p className="text-danger"> {errors.description} </p>)}
                                                    </div>
                                                </div>
                                                <div className="col-12 my-5 text-center">
                                                    <button type="submit" className="allBtns w-100">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </section>
        </>
    )
}

export default PettyCash
