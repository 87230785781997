import React, { useEffect, useState } from 'react'
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { deleteApi, getApi, postApi, putApi, } from "../../utilits/helpers/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';
import { Typeahead } from "react-bootstrap-typeahead";
// import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import FileDownloadImg from '../../assets/images/file.png'
import ReactPaginate from 'react-paginate';

const FeeCollections = () => {
    const { checkAuth } = CheckLogin();
    const [isAdd, setIsAdd] = useState(false);
    const [students, setStudents] = useState([]);
    const [searchType, setSearchType] = useState("student_id");
    const [studentFeeCollections, setStudentFeeCollections] = useState([]);
    const [feeId, setFeeId] = useState("");
    const [studentIdIndex, setStudentIdIndex] = useState(null);
    const [studentFeeData, setStudentFeeData] = useState({});
    const [studentPaidFeeData, setStudentPaidFeeData] = useState({});
    const [studentFeeNeedToPaid, setStudentFeeNeedToPaid] = useState({
        admission_balance: 0,
        tution_balance: 0,
        transport_balance: 0,
        total_balance: 0
    });
    const [studentInfo, setStudentInfo] = useState({});
    const [formData, setFormData] = useState({
        student_id: "",
        fee_date: "",
        dueDate: "",
        admission_fee: '0',
        tution_fee: '0',
        transport_fee: '0',
        total_fee_paid: '0'
    });
    const [errors, setErrors] = useState({});

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const [pageLimits, setPageLimits] = useState(10);
    const [recordPerPage, setRecordPerPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [prepareXlData, setPrepareXlData] = useState([]);
    const [xlData, setXlData] = useState([]);
    const [page, setPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);

    useEffect(() => {
        const feeCollectionXlPrepare = prepareXlData.map((feeCollection, index) => ({
            S_No: index + 1,
            student_id: feeCollection.student_id.student_id,
            student_fullname: feeCollection.student_id.student_fullname,
            class_id: feeCollection.class_id.class,
            section: feeCollection.class_id.section,
            admission_fee: feeCollection.admission_fee,
            dueDate: new Date(feeCollection.dueDate).toLocaleDateString('en-GB'),
            fee_date: new Date(feeCollection.fee_date).toLocaleDateString('en-GB'),
            total_fee_paid: feeCollection.total_fee_paid,
            transport_fee: feeCollection.transport_fee,
            tution_fee: feeCollection.tution_fee,
        }));
        setXlData(feeCollectionXlPrepare);
    }, [prepareXlData]);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(xlData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "FeeCollection");
        XLSX.writeFile(workbook, "FeeCollection.xlsx");
    }

    const fetchData = async (student_id = null, page = 1, limit = 10) => {
        try {
            let url = `/fee-collect/get?page=${page ? page : 1}&limit=${pageLimits}`
            if (student_id) {
                url += `&student_id=${student_id}`
            }
            const response = await getApi(url);
            setPrepareXlData(response?.response?.studentFeeList);
            setRecordsPerPage(response.response.recordsPerPage);
            setTotalPages(response.response.totalPages);
            setTotalRecords(response.response.total);
            setStudentFeeCollections([...response.response.studentFeeList]);
            setStudentFeeData({ ...response?.response?.totalFee });
            setStudentPaidFeeData({ ...response?.response?.totalFeeCollected });
            if (student_id) {
                const feeObj = {
                    admission_balance: response?.response?.totalFee?.totalAdmissionFee - response?.response?.totalFeeCollected?.totalAdmissionFee,
                    tution_balance: response?.response?.totalFee?.totalNetTutionFee - response?.response?.totalFeeCollected?.totalNetTutionFee,
                    transport_balance: response?.response?.totalFee?.totalTransportationAmount - response?.response?.totalFeeCollected?.totalTransportationAmount,
                }
                setStudentFeeNeedToPaid(feeObj)
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchAllStudents = async (text = null) => {
        try {
            let url = '/admission/students'
            if (searchType && text) {
                url += `?${searchType}=${text}`
            }
            const response = await getApi(url);
            setStudents([...response.response.data]);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    const onSearchSelect = (text, e) => {
        console.log("hello text", text, e);
        setStudentInfo(text[0]);
        setFormData({
            ...formData,
            student_id: text[0]?._id
        });
        fetchData(text[0]?._id);
    }

    const onSearchFeeSelect = (text, e) => {
        setStudentInfo(text[0]);
        fetchData(text[0]?._id);
    }

    const onSearchChange = (text, e) => {
        console.log(text, e);
        fetchAllStudents(text);
    }
    useEffect(() => {
        fetchAllStudents();
        checkAuth();
    }, [])

    useEffect(() => {
        fetchData(studentInfo?._id, page, recordsPerPage);
    }, [page, recordsPerPage])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: "",
        });
    };



    const updateData = async (data, id) => {
        try {
            const { admission_fee, tution_fee, transport_fee } = formData;
            const totalFee = Number(admission_fee) + Number(tution_fee) + Number(transport_fee);
            const payload = {
                student_id: data.student_id,
                fee_date: data.fee_date,
                dueDate: data.dueDate,
                admission_fee: data.admission_fee,
                tution_fee: data.tution_fee,
                transport_fee: data.transport_fee,
                total_fee_paid: totalFee.toString()
            }
            const updateStudentData = await putApi(`/fee-collect/update/${id}`, payload);
            setFeeId('');
            setFormData({
                student_id: "",
                fee_date: "",
                dueDate: "",
                admission_fee: '0',
                tution_fee: '0',
                transport_fee: '0',
                total_fee_paid: '0'
            });
            let successMessage = "Student fee Data has Been Updated successfully";
            toast.success(successMessage);
            fetchData();
            setIsAdd(false)
            console.log(updateStudentData);
        } catch (error) {
            console.log("Error caught:", error);
            let errorMessage = "An error occurred";
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === "string") {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = "No response received from server";
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    };

    const postData = async (data) => {
        // console.log(data)   
        const { admission_fee, tution_fee, transport_fee } = formData;
        const totalFee = Number(admission_fee) + Number(tution_fee) + Number(transport_fee);
        const payload = {
            student_id: data.student_id,
            fee_date: data.fee_date,
            dueDate: data.dueDate,
            admission_fee: data.admission_fee,
            tution_fee: data.tution_fee,
            transport_fee: data.transport_fee,
            total_fee_paid: totalFee.toString()
        }
        try {
            const response = await postApi("/fee-collect/create", payload);
            setFormData({
                student_id: "",
                fee_date: "",
                dueDate: "",
                admission_fee: '0',
                tution_fee: '0',
                transport_fee: '0',
                total_fee_paid: '0'
            });
            let successMessage = "Student fee created successfully";
            toast.success(successMessage);
            fetchData();
            setIsAdd(false)
            console.log(response);
        } catch (error) {

            let errorMessage = "An error occurred";
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === "string") {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = "No response received from server";
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    };

    const submitStudent = (e) => {
        e.preventDefault();
        // calculateTotalFee();
        console.log('Form Data:', formData);

        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            if (feeId) {
                updateData(formData, feeId);
            } else {
                postData(formData);
                // console.log("i am in muzamill")
            }
        }
        setStudentIdIndex(null);
    };

    const validate = (data) => {
        const errors = {};
        if (!data.student_id) errors.student_id = "Please Enter the Student ID";
        if (!data.fee_date) errors.fee_date = "Please Enter the Fee Date";
        if (!data.dueDate) errors.dueDate = "Please Enter the Fee Date";
        if (!data.admission_fee && data.admission_fee > 0) errors.admission_fee = "Please Enter the Admission Fee";
        if (!data.tution_fee && data.tution_fee > 0) errors.tution_fee = "Please Enter the Tution Fee";
        if (!data.transport_fee && data.transport_fee > 0) errors.transport_fee = "Please Enter the Transport Fee";

        if (Number(data.admission_fee) > 0 && Number(data.admission_fee) > studentFeeNeedToPaid.admission_balance) {
            errors.admission_fee = "Admission Fee can't be greater than Admission Fee Balance";
        }
        if (Number(data.tution_fee) > 0 && Number(data.tution_fee) > studentFeeNeedToPaid.tution_balance) {
            errors.tution_fee = "Tution Fee can't be greater than Tution Fee Balance";
        }
        if (Number(data.transport_fee) > 0 && Number(data.transport_fee) > studentFeeNeedToPaid.transport_balance) {
            errors.transport_fee = "Transport Fee can't be greater than Transport Fee Balance";
        }
        return errors;
    };

    const fetchEditData = async (item) => {
        setFormData({
            ...formData,
            student_id: item.student_id._id,
            fee_date: new Date(item.fee_date).toISOString().substr(0, 10),
            dueDate: new Date(item.dueDate).toISOString().substr(0, 10),
            admission_fee: item.admission_fee,
            tution_fee: item.tution_fee,
            transport_fee: item.transport_fee,
            total_fee_paid: item.total_fee_paid
        });
        setFeeId(item._id);
        setIsAdd(true)
        let stuindx = 0;
        students.map((value, index) => {
            if (value._id === item.student_id._id) {
                stuindx = index;
            }
        })
        setStudentIdIndex(stuindx);
    }

    const deleteFeeData = async (id) => {
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            reverseButtons: true
        });
        if (willDelete.isConfirmed) {
            try {
                const deleteAdmission = await deleteApi(`/fee-collect/delete/${id}`);
                let successMessage = "Fee Data has Been Deleted successfully";
                toast.success(successMessage);
                fetchData();
                console.log(deleteAdmission);
            } catch (error) {
                console.log('Error caught:', error);
                let errorMessage = 'An error occurred';
                if (error.response) {
                    // Extracting the specific message from the server response
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                    }
                } else if (error.request) {
                    errorMessage = 'No response received from server';
                } else {
                    errorMessage = error.message;
                }

                toast.error(errorMessage);
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Admission Data is safe :)", "info");
        }
    }

    const navigate = useNavigate();
    const handleDownloadClick = (item) => {
        navigate('/print-bill', { state: { feeData: item } });
    };

    const resetForm = (flag) => {
        setIsAdd(flag);
        setFeeId('');
        setFormData({
            student_id: "",
            fee_date: "",
            dueDate: "",
            admission_fee: '0',
            tution_fee: '0',
            transport_fee: '0',
            total_fee_paid: '0'

        });

    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1);

    };

    const changePageLimit = (event) => {
        setRecordsPerPage(event.target.value);
        setPage(1);
        setPageLimits(event.target.value);
    }

    return (
        <>
            <section className="accountSec">
                <ToastContainer />
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-3" style={{ backgroundColor: "Red", color: "white" }}>
                            <h5 style={{ color: "white" }}>Total Fees</h5>
                            <p style={{ color: "white" }}><b>Admission Fee:</b> {studentFeeData.totalAdmissionFee}</p>
                            <p style={{ color: "white" }}><b>Tution Fee:</b> {studentFeeData.totalNetTutionFee}</p>
                            <p style={{ color: "white" }}><b>Transport Fee:</b> {studentFeeData.totalTransportationAmount}</p>

                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-3" style={{ backgroundColor: "blue", color: "white" }}>
                            <h5 style={{ color: "white" }}>Fee Collected</h5>
                            <p style={{ color: "white" }}><b>Admission Fee:</b> {studentPaidFeeData.totalAdmissionFee}</p>
                            <p style={{ color: "white" }}><b>Tution Fee:</b> {studentPaidFeeData.totalNetTutionFee}</p>
                            <p style={{ color: "white" }}><b>Transport Fee:</b> {studentPaidFeeData.totalTransportationAmount}</p>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12 p-3" style={{ backgroundColor: "green", color: "white" }}>
                            <h5 style={{ color: "white" }}>Need To Pay</h5>
                            <p style={{ color: "white" }}><b>Admission Fee:</b> {studentFeeNeedToPaid.admission_balance}</p>
                            <p style={{ color: "white" }}><b>Tution Fee:</b> {studentFeeNeedToPaid.tution_balance}</p>
                            <p style={{ color: "white" }}><b>Transport Fee:</b> {studentFeeNeedToPaid.transport_balance}</p>
                        </div>
                    </div>
                    <div className="row">
                        {!isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row stripBg">
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 stripBg p-3">
                                                <h5>FEE COLLECTION</h5>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 p-3 stripBg " style={{ width: 400 }}>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ marginRight: 5 }}>
                                                        <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
                                                            <option value="student_id">Student ID</option>
                                                            <option value="student_fullname">Full Name</option>
                                                            <option value="father_mobile_no">Father Phone Number</option>
                                                            <option value="mother_mobile_no">Mother Phone Number</option>
                                                        </select>
                                                    </div>
                                                    <div>
                                                        <Typeahead
                                                            // defaultSelected={options.slice(0, 1)}

                                                            labelKey={searchType}
                                                            onInputChange={onSearchChange}
                                                            onChange={onSearchFeeSelect}
                                                            options={students}
                                                            id="selections-example"
                                                            placeholder="Choose a Student..."
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-2 col-sm-4 col-12 p-3 stripBg text-lg-end text-md-start text-sm-end">
                                            <button onClick={downloadExcel} className="allBtns"><img src={FileDownloadImg} width="25px" alt="" /></button> <button className="hideAndShowBtns" onClick={() => resetForm(true)}>Add +</button>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-12 py-3 px-0">
                                                <div className="body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover m-0">
                                                            <thead>
                                                                <tr className="text-center tableHead">
                                                                    <th>S.No</th>
                                                                    <th>Student Name</th>
                                                                    <th>Student ID</th>
                                                                    <th>Class</th>
                                                                    <th>Fee Date</th>
                                                                    <th>Tution Fee</th>
                                                                    <th>Transport Fee</th>
                                                                    <th>Total Fee Paid</th>
                                                                    <th>Due Date</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {studentFeeCollections ? (
                                                                    <>
                                                                        {studentFeeCollections.map((item, index) => (
                                                                            <tr className="text-center" key={index}>
                                                                                <td>{index + 1}</td>
                                                                                <td>{item?.student_id?.student_fullname}</td>
                                                                                <td>{item?.student_id?.student_id}</td>
                                                                                <td>{item?.class_id?.class}-{item?.class_id?.section}</td>
                                                                                <td>{new Date(item.fee_date).toLocaleDateString('en-US')}</td>
                                                                                <td>{item.tution_fee}</td>
                                                                                <td>{item.transport_fee}</td>
                                                                                <td>{item.total_fee_paid}</td>
                                                                                <td>{new Date(item.dueDate).toLocaleDateString('en-US')}</td>
                                                                                <td>
                                                                                    <i className="fa-solid fa-pen me-3 text-success" onClick={() => fetchEditData(item)} ></i>
                                                                                    <i className="fa-solid fa-trash text-danger" onClick={() => deleteFeeData(item._id)}></i>
                                                                                    <i className="fa fa-download ms-3 text-warning" onClick={() => handleDownloadClick(item)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr className=''>
                                                                        <td className='text-center'>Pages: {totalPages}</td>
                                                                        <td className='text-center'>Per pages: {recordsPerPage}</td>
                                                                        <td className='text-center'>Records: {totalRecords}</td>
                                                                        <td className='d-flex align-items-center justify-content-center'>
                                                                            <input className='form-control text-center' style={{ width: '75px' }} type="number" value={pageLimits} onChange={changePageLimit} />
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                                    : (
                                                                        <tr>
                                                                            <td colSpan="4" className="text-center">No Fee Collected</td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                        <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={totalPages}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                                                <h5>FEE COLLECTION INFORMATION</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                                <button className="hideAndShowBtns" onClick={() => resetForm(false)}>Back</button>
                                            </div>
                                        </div>
                                        <form
                                            name="teacherForm"
                                            id="teacherForm"
                                            onSubmit={submitStudent}
                                        >
                                            <div className="row px-2">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group" style={{ width: 400 }}>
                                                        <label className="form-label">
                                                            Search Student<span className="text-danger">*</span>
                                                        </label>
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ marginRight: 5 }}>
                                                                <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
                                                                    <option value="student_id">Student ID</option>
                                                                    <option value="student_fullname">Full Name</option>
                                                                    <option value="father_mobile_no">Father Phone Number</option>
                                                                    <option value="mother_mobile_no">Mother Phone Number</option>
                                                                </select>

                                                            </div>
                                                            <div>
                                                                <Typeahead
                                                                    defaultSelected={students.slice(studentIdIndex, 1)}
                                                                    labelKey={searchType}
                                                                    onInputChange={onSearchChange}
                                                                    onChange={onSearchSelect}
                                                                    options={students}
                                                                    id="selections-example"
                                                                    placeholder="Select a Student..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3 d-flex align-items-center">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6>Parent Mobile Number: {studentInfo?.father_mobile_no}</h6>
                                                            <h6 className='mt-2'>Student Name: {studentInfo?.student_fullname}</h6>
                                                        </div>
                                                         
                                                        <div className="col-12 mt-2">
                                                            <h6>Class: {studentInfo && studentInfo.class_id ? studentInfo.class_id.class : ''}, Section: {studentInfo && studentInfo.class_id ? studentInfo.class_id.section : ''}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="fee_date" className="form-label">
                                                            Fee Date<span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            autoComplete="off"
                                                            type="date"
                                                            name="fee_date"
                                                            id="fee_date"
                                                            value={formData.fee_date}
                                                            onChange={handleChange}
                                                            placeholder="Fee Date"
                                                        />
                                                        {errors.fee_date && <p className="text-danger">{errors.fee_date}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="effort" className="form-label">
                                                            Admission Fee
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" name='admission_fee' id='admission_fee' placeholder='Admission Fee' onChange={handleChange} value={formData.admission_fee} />
                                                        {errors.admission_fee && <p className="text-danger">{errors.admission_fee}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="tution_fee" className="form-label">
                                                            Tution Fee
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" name='tution_fee' id='tution_fee' placeholder='Tution Fee' onChange={handleChange} value={formData.tution_fee} />
                                                        {errors.tution_fee && <p className="text-danger">{errors.tution_fee}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="transport_fee" className="form-label">
                                                            Transport Fee
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" name='transport_fee' id='transport_fee' placeholder='Transport Fee' onChange={handleChange} value={formData.transport_fee} />
                                                        {errors.transport_fee && <p className="text-danger">{errors.transport_fee}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="dueDate" className="form-label">
                                                            Next Fee Due Date
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="date" name='dueDate' id='dueDate' placeholder='Next Fee Due Date' onChange={handleChange} value={formData.dueDate} />
                                                        {errors.dueDate && <p className="text-danger">{errors.dueDate}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="total_fee_paid" className="form-label">
                                                            Total Fee Paid
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input disabled type="text" name='total_fee_paid' id='total_fee_paid' placeholder='Total Fee Paid' value={formData.total_fee_paid} />
                                                        <span>NOTE: Total fee will update on submit</span>
                                                        {errors.total_fee_paid && <p className="text-danger">{errors.total_fee_paid}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-12 my-5 text-center">
                                                    <button type="submit" className="allBtns w-100">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeeCollections
