import './App.css';
import RootRouter from './pages/RootRouter';

function App() {
  return (
    <div className="App">
      <RootRouter />
    </div>
  );
}

export default App;
