import React, { useState, useEffect } from 'react'
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { deleteApi, getApi, postApi, putApi } from '../../utilits/helpers/apiService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
export default function Index() {
    const { checkAuth } = CheckLogin();
    const [accountData, setAccountData] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        dob: '',
        role: '',
        status: '',
        username: '',
        phone_number: '',
        password: '',
        re_password: '',
    })
    const [errors, setErrors] = useState({});

    const [isAdd, setIsAdd] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const fetchData = async () => {
        try {
            const response = await getApi('/account/get');
            console.log(response);
            setAccountData(response.response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUserData = async (id) => {
        setIsAdd(true);
        try {
            const userData = accountData.find(user => user._id === id);
            console.log(userData);
            setAccountId(id);
            setFormData({
                full_name: userData.full_name || '',
                email: userData.email || '',
                dob: userData.dob || '',
                role: userData.role || '',
                status: userData.status || '',
                username: userData.username || '',
                phone_number: userData.phone_number || '',
                password: userData.re_password || '',
                re_password: userData.re_password || '',
            });

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        checkAuth();
    }, []);

    const postData = async (data) => {
        try {
            const response = await postApi('/account/create', data);
            setFormData({
                full_name: '',
                email: '',
                dob: '',
                role: '',
                status: '',
                username: '',
                phone_number: '',
                password: '',
                re_password: '',
            })
            let successMessage = "User created successfully";
            toast.success(successMessage);
            setIsAdd(false);
            fetchData();
            console.log(response);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);

        }
    }
    
    const updateData = async (data, id) => {
        try {
            const updateUserData = await putApi(`/account/update/${id}`, data);
            setAccountId('');
            setFormData({
                full_name: '',
                email: '',
                dob: '',
                role: '',
                status: '',
                username: '',
                phone_number: '',
                password: '',
                re_password: '',
            })
            let successMessage = "User Data has Been Updated successfully";
            toast.success(successMessage);
            setIsAdd(false);
            fetchData();
            console.log(updateUserData);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    }
    const submitAccount = (e) => {
        e.preventDefault();
        // console.log('Form Data:', formData);
        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            if (accountId) {
                updateData(formData, accountId);
            } else {
                postData(formData);
            }

        }
    };


    const validate = (data) => {
        const errors = {};
        if (!data.full_name) errors.full_name = 'Please Enter the Full Name';
        if (!data.email) {
            errors.email = 'Please Enter the Email';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            errors.email = 'Email is invalid';
        }
        if (!data.dob) errors.dob = 'Please Enter the Date of Birth';
        if (!data.role) errors.role = 'Select the Role';
        if (!data.status) errors.status = 'Select the Status';
        if (!data.username) errors.username = 'Please Enter the Username';
        // if (!data.phone_number) errors.phone_number = 'Please Enter the Phone Number';
        if (!data.phone_number) {
            errors.phone_number = 'Please Enter the Phone Number';
        } else if (data.phone_number.length > 12 || data.phone_number.length < 10) {
            errors.phone_number = 'Invalid Phone Number';
        }
        if (!data.password) {
            errors.password = 'Please Enter the Password';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(data.password)) {
            errors.password = 'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character';
        }
        if (!data.re_password) {
            errors.re_password = 'Please Enter the Re-Password';
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(data.re_password)) {
            errors.re_password = 'Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character';
        }
        else if (data.password !== data.re_password) {
            errors.re_password = 'Password and Re-Password do not match';
        }
        return errors;
    };

    const deleteAccount = async (id) => {
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this account!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            reverseButtons: true
        });
        if (willDelete.isConfirmed) {
            try {
                const deteleUserData = await deleteApi(`/account/delete/${id}`);
                let successMessage = "User Data has Been Deleted successfully";
                toast.success(successMessage);
                fetchData();
                console.log(deteleUserData);
            } catch (error) {
                console.log('Error caught:', error);
                let errorMessage = 'An error occurred';
                if (error.response) {
                    // Extracting the specific message from the server response
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                    }
                } else if (error.request) {
                    errorMessage = 'No response received from server';
                } else {
                    errorMessage = error.message;
                }

                toast.error(errorMessage);
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your account is safe :)", "info");
        }
    }



    return (
        <>
            <section className='accountSec'>
                <div className="container">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row">
                        {(!isAdd && (
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card p-0">
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                                            <h5>ALL ACCOUNTS</h5>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                            <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <input type="search" placeholder='Search Accounts' />
                                        </div>
                                        <div className="col-12 py-3 px-0">
                                            <div className="body p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-hover m-0">
                                                        <thead>
                                                            <tr className="text-center tableHead">
                                                                <th>S.No</th>
                                                                <th>Full Name</th>
                                                                <th>Status</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {accountData ? (
                                                                accountData.map((account, index) => (
                                                                    <tr key={index} className="text-center">
                                                                        <td>{index + 1}</td>
                                                                        <td>{account.full_name}</td>
                                                                        <td>{account.status === 1 ? 'Active' : 'Inactive'}</td>
                                                                        <td>
                                                                            <i className="fa-solid fa-pen me-3 text-success" onClick={() => fetchUserData(account._id)} ></i>
                                                                            <i className="fa-solid fa-trash text-danger" onClick={() => deleteAccount(account._id)}></i>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="4" className="text-center">No accounts found</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                        { isAdd && (
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card p-0">
                                <div className="container">
                                    <div className="row stripBg">
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-12  p-3">
                                            <h5>ACCOUNTS INFORMATION</h5>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 text-lg-end text-md-end text-sm-end">
                                            <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                                        </div>
                                    </div>
                                    <form name='accountForm' id='accountForm' onSubmit={submitAccount}>
                                        <div className="row px-2">
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="full_name" className="form-label">Full Name<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="full_name" id="full_name" placeholder="Full Name" value={formData.full_name} onChange={handleChange} />
                                                    {errors.full_name && <span className="error">{errors.full_name}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="email" className="form-label">Email<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="email" id="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                                    {errors.email && <span className="error">{errors.email}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="dob" className="form-label">Date of Birth<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="date" name="dob" id="dob" placeholder="Date of Birth" value={formData.dob} onChange={handleChange} />
                                                    {errors.dob && <span className="error">{errors.dob}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="role" className="form-label">Select the Role<span className="text-danger">*</span></label>
                                                    <select name="role" id="role" value={formData.role} onChange={handleChange}>
                                                        <option value="" disabled>Select the Role</option>
                                                        <option value="1">Admin</option>
                                                        <option value="2">Accountant</option>
                                                    </select>
                                                    {errors.role && <span className="error">{errors.role}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="status" className="form-label">Select the Status<span className="text-danger">*</span></label>
                                                    <select name="status" id="status" value={formData.status} onChange={handleChange}>
                                                        <option value="" disabled>Select the status</option>
                                                        <option value="1">Active</option>
                                                        <option value="0">In Active</option>
                                                    </select>
                                                    {errors.status && <span className="error">{errors.status}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="username" className="form-label">User Name<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="username" id="username" placeholder="User Name" value={formData.username} onChange={handleChange} />
                                                    {errors.username && <span className="error">{errors.username}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="phone_number" className="form-label">Phone Number<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="number" name="phone_number" id="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={handleChange} />
                                                    {errors.phone_number && <span className="error">{errors.phone_number}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="password" className="form-label">Password<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="password" name="password" id="password" placeholder="Password" value={formData.password} onChange={handleChange} />
                                                    {errors.password && <span className="error">{errors.password}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                <div className="input-group">
                                                    <label htmlFor="re_password" className="form-label">Re-enter Password<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="password" name="re_password" id="re_password" placeholder="Re-enter Password" value={formData.re_password} onChange={handleChange} />
                                                    {errors.re_password && <span className="error">{errors.re_password}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 my-5 text-center">
                                                <button type="submit" className="allBtns w-100">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}
