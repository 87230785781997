import React, { useEffect, useState } from "react";
import { CheckLogin } from '../utilits/helpers/ChechLogin';
import { getApi, postApi } from "../utilits/helpers/apiService";
import VerticalGraph from "../Components/DashobardComp/VerticalGraph";
import { ToastContainer, toast } from "react-toastify";

export default function Dashboard() {
  const { checkAuth } = CheckLogin();
  const [data, setData] = useState([]);
  const [studentsListLabel, setStudentsListLabel] = useState([]);
  const [studentsListCount, setStudentsListCount] = useState([]);
  const [feeCollectedList, setFeeCollectedList] = useState([]);
  const [feePendingList, setFeePendingList] = useState([]);
  const [customeShow, setCustomeShow] = useState(false);
  const [admissionData, setAdmissionData] = useState([]);
  const [admissionDataLabel, setAdmissionDataLabel] = useState([]);
  const [totalAdmissions, setTotalAdmissions] = useState(0);
  const [totalStudents, setTotalStudents] = useState(0);
  const [schoolTotalPendingFee, setSchoolTotalPendingFee] = useState(0);
  const [schoolTotalFeeColected, setSchoolTotalFeeColected] = useState(0);
  const [totalActiveTeachers, setTotalActiveTeachers] = useState(0);
  const [feeCollectedLabel, setFeeCollectedLabel] = useState([]);
  const [feePendingLabel, setFeePendingLabel] = useState([]);
  const [classWiseTotalFee, setClassWiseTotalFee] = useState([]);
  const [classWiseTotalFeeLabel, setClassWiseTotalFeeLabel] = useState([]);
  const [schoolTotalFee, setSchoolTotalFee] = useState(0);
  const [pettyCashLables, setPettyCashLables] = useState([]);
  const [pettyCashData, setPettyCashData] = useState([]);
  
  // http://localhost:9000/api/v1/insights

  const getInsights = async (type = 'all') => {
    const students = [];
    const classes = [];
    const feeCollected = [];
    const feePending = [];
    const allClasses = [];
    const allAdmissions = [];
    let url = `/insights?type=${type}`;
    if (type === 'custome') {
      const fromDate = document.getElementById('from').value
      const toDate = document.getElementById('toDate').value;
      if (fromDate && toDate !== '') {
        if (toDate < fromDate) {
          toast.error('To Date should be greater than From Date')
        }
      }
      else {
        url += `&from=${fromDate}&to=${toDate}`;
      }
    }
    const res = await getApi(url);
    setData(res.response);
    console.log("bbbcbcbbc", res.response);

    res.response.classList.map((item) => {
      students.push(item.totalstudents);
      classes.push(item.class);
      feeCollected.push(item.class_total_fee_collected);
      feePending.push(item.class_pending_fee);
    });

    // allClasses.push(...res.response.class_list);
    allAdmissions.push(...res.response.addmissionCountList);
    setAdmissionDataLabel(res.response.class_label_admission);
    setAdmissionData(allAdmissions);
    setTotalAdmissions(res.response.totalAddimission)
    // setClassList(allClasses);
    setStudentsListLabel(res.response.class_label_students);
    setStudentsListCount(res.response.studentcountList);
    setSchoolTotalFeeColected(res.response.schoolTotalFeeColected)
    setSchoolTotalPendingFee(res.response.schoolTotalPendingFee)
    setTotalStudents(res.response.totalStudents)
    setFeeCollectedLabel(res.response.class_label_fees_collected);
    setFeePendingLabel(res.response.class_label_fees_pendding);
    setFeeCollectedList(res.response.feeCollectedList);
    setFeePendingList(res.response.feePendingList);
    setTotalActiveTeachers(res.response.totalActiveTeachers)
    setClassWiseTotalFee(res.response.totalFeesList)
    setClassWiseTotalFeeLabel(res.response.class_label_total_fee)
    setSchoolTotalFee(res.response.schoolTotalFee)
  }



  const typeHandleChange = async (event) => {
    const value = event.target.value;
    setCustomeShow(value === 'custome');

    if (value === 'custome') {
      return;
    }

    try {
      getInsights(value);
    } catch (error) {
      console.log(error)
    }
  };
  
  const getPettyCash = async (type) => {
    let pettyUrl = `/insights/petty-cash`;
    if (type === 'petty-cash') {
      const month = document.getElementById('pettyCashMOnth').value
      const year = document.getElementById('pettyCashYear').value;
      pettyUrl += `?year=${year}&month=${month}`;
      const pettyCashData = await getApi(pettyUrl)
      setPettyCashLables(pettyCashData.response.dateLables)
      setPettyCashData(pettyCashData.response.pettyCash)
    }
    const pettyCashData = await getApi(pettyUrl);
    setPettyCashLables(pettyCashData.response.dateLables)
    setPettyCashData(pettyCashData.response.pettyCash)
  };
  const pettyHandleChange = async () => {
    const month = document.getElementById('pettyCashMOnth').value
    const year = document.getElementById('pettyCashYear').value;
    try {
      getPettyCash('petty-cash');
    } catch (error) {
      console.log(error)
    }
  };
  const resetPettyCash = async () => {
    try {
      document.getElementById('pettyCashMOnth').value='';
      getPettyCash();
    } catch (error) {
      console.log(error)
    }
  };



  const handleFromAndToDates = async () => {
    const fromDate = document.getElementById('from').value
    const toDate = document.getElementById('toDate').value;
    if (fromDate && toDate !== '') {
      if (toDate < fromDate) {
        toast.error('To Date should be greater than From Date')
      }
      else {
        try {
          getInsights('custome');
        } catch (error) {
          console.log(error)
        }
      }
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    checkAuth();
    getInsights();
    getPettyCash();
  }, []);


  const studentsGraphData = {
    labels: studentsListLabel, // Ensure this is an array
    datasets: [
      {
        label: `Students Class Wise - ( ${totalStudents} )`,
        data: studentsListCount ? studentsListCount : [],
        backgroundColor: 'rgba(6, 38, 102, 1)',
        borderColor: 'rgba(6, 38, 102, 1)',
        borderWidth: 1,
      },
    ],
    context2: 'Students Data' // This will be used as the chart title
  };


  const admissionsGraphData = {
    labels: admissionDataLabel, // Ensure this is an array
    datasets: [
      {
        label: `Admission Class Wise - ( ${totalAdmissions} )`,
        data: admissionData ? admissionData : [],
        backgroundColor: 'rgba(6, 38, 102, 1)',
        borderColor: 'rgba(6, 38, 102, 1)',
        borderWidth: 1,
      },
    ],
    context2: 'Admissions Data' // This will be used as the chart title
  };

  const FeeCollectedData = {
    labels: feeCollectedLabel, // Ensure this is an array
    datasets: [
      {
        label: `Fee Collected - ( ${schoolTotalFeeColected} )`,
        data: feeCollectedList ? feeCollectedList : [],
        backgroundColor: 'rgb(99 255 137 / 20%)',
        borderColor: 'rgba(99 255 137 / 80%)',
        borderWidth: 1,
      }
    ],
    context2: 'Collected Fee Data' // This will be used as the chart title
  };

  const FeePendingData = {
    labels: feePendingLabel, // Ensure this is an array
    datasets: [
      {
        // label: "Fee Due",
        label: `Fee Pending - ( ${schoolTotalPendingFee} )`,
        data: feePendingList ? feePendingList : [], // Make sure to define `dueData`
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      }
    ],
    context2: 'Pending Fee Data' // This will be used as the chart title
  };

  const TotalFeeClassWise = {
    labels: classWiseTotalFeeLabel, // Ensure this is an array
    datasets: [
      {
        // label: "Fee Due",
        label: `Total Fee - ( ${schoolTotalFee} )`,
        data: classWiseTotalFee ? classWiseTotalFee : [], // Make sure to define `dueData`
        backgroundColor: 'rgba(6, 38, 102, 1)',
        borderColor: 'rgba(6, 38, 102, 1)',
        borderWidth: 1,
      }
    ],
    context2: 'Total Fee Data' // This will be used as the chart title
  };

  const pettyCashGraphData = {
    labels: pettyCashLables, // Ensure this is an array
    datasets: [
      {
        label: `Petty Cash Data `,
        // ( ${totalAdmissions} )
        data: pettyCashData ? pettyCashData : [],
        backgroundColor: 'rgba(6, 38, 102, 1)',
        borderColor: 'rgba(6, 38, 102, 1)',
        borderWidth: 1,
      },
    ],
    context2: 'Petty Cash Data' // This will be used as the chart title
  };

   

  return (
    <>
      <section className="p-0">
        <ToastContainer />
        <div className="container">

          <div className="row mt-3">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12"></div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <h4>Filter</h4>
              <div className="col-12">
                <select name="type" id="type" onChange={typeHandleChange}>
                  <option value="">Select the Filter</option>
                  <option value="all" selected>All</option>
                  <option value="today">Today</option>
                  <option value="seven">This Week</option>
                  <option value="fourteen">Last 15 Days</option>
                  <option value="thirty">This Month</option>
                  {/* <option value="sixty">Last 2 Month</option> */}
                  <option value="custome">Custom</option>
                </select>
              </div>
            </div>
            {customeShow && (
              <>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12"></div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="from">From Date</label>
                      <input type="date" className="fromDate" id="from" placeholder="From Date" name="fromDate" onChange={handleFromAndToDates} />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="from">To Date</label>
                      <input type="date" className="toDate" id="toDate" placeholder="To Date" name="toDate" onChange={handleFromAndToDates} />
                    </div>
                  </div>
                </div></>
            )}
          </div>

          <div className="row mt-5">
            
            <div className="col-lg-6">
               
              <VerticalGraph data={FeeCollectedData} />
            </div>
            <div className="col-lg-6">
               
              <VerticalGraph data={FeePendingData} />
            </div>
            <div className="col-lg-8 offset-lg-2">
               
              <VerticalGraph data={TotalFeeClassWise} />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-6">
                  <h2>Admissions Data</h2>
                  {/* <VerticalGraph data={{ label: classList, counts: studentsList, context: 'Students in Class', context2: 'Students' }} /> */}
                  <VerticalGraph data={admissionsGraphData} />
                </div>

                <div className="col-lg-6">
                  <h2>Students Data</h2>
                  {/* <VerticalGraph data={{ label: classList, counts: studentsList, context: 'Students in Class', context2: 'Students' }} /> */}
                  <VerticalGraph data={studentsGraphData} />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-4"></div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                  <label htmlFor="pettyCashMOnth">Petty Cash Filter</label>
                  <select name="pettyCashMOnth" id="pettyCashMOnth" onChange={pettyHandleChange} >
                    <option value="" selected>Select The Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                  <label htmlFor="pettyCashYear">Petty Cash Filter</label>
                  <select name="pettyCashYear" id="pettyCashYear" onChange={pettyHandleChange}>
                    <option value="" >Select The Year</option>
                    <option value="2024" selected>2024</option>
                    <option value="2025">2025</option>
                  </select>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12 col-12 mt-4">
                  <button className="allBtns" onClick={resetPettyCash}>Reset</button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 offset mt-3">
              <VerticalGraph data={pettyCashGraphData} />
            </div>
          </div>


          <div className="row mt-5 ">
            <div className="col-12 ">
              <h4>Teachers Data</h4>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card">
                <div className="container">
                  <div className="row p-3">
                    <div className="col-12 text-center">
                      <h4>Total Active Teachers</h4>
                      <h6 className="pt-3">{totalActiveTeachers}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>
      </section>
    </>
  );
}
