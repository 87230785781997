// src/components/Header.js
import React from 'react';
import hideSideBar from '../assets/images/hideSideBar.png';
import openSideBar from '../assets/images/openSideBar.png';
const Header = ({ toggleSidebar, showSidebar, pageTitle }) => {
  return (
    <header className="header">
      <button onClick={toggleSidebar} className="toggle-button">
        {showSidebar ? <img src={hideSideBar} width="24px" alt="hideSideBar" /> : <img src={openSideBar} width="24px" alt="openSideBar" />}
      </button>
      <h4>{pageTitle}</h4>
    </header>
  );
};

export default Header;
