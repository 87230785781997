import React, { useEffect, useState } from "react";
import {
  deleteApi,
  getApi,
  postApi,
  putApi,
} from "../../utilits/helpers/apiService";
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Classes = () => {
  const { checkAuth } = CheckLogin();
  const [errors, setErrors] = useState({});
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [classesFormData, setClassesFormData] = useState({
    class: "",
    section: "",
  });

  const fetchClasses = async () => {
    try {
      const res = await getApi("/class/get");
      console.log(res.response);
      setClasses(res.response);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchClasses();
    checkAuth();
  }, [])

  const postClassData = async (data) => {
    try {
      await postApi("/class/create", data);
      // console.log(postClassData);
      setClassesFormData({
        class: "",
        section: "",
      });
      fetchClasses();
      setIsAdd(false);
      let saveClassSuccessMessage = "New class created successfully";
      toast.success(saveClassSuccessMessage);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.saveClassData) {
        // Extracting the specific message from the server response
        if (error.saveClassData.data && error.saveClassData.data.message) {
          errorMessage = error.saveClassData.data.message;
        } else if (typeof error.saveClassData.data === "string") {
          errorMessage = error.saveClassData.data;
        } else {
          errorMessage = `Error: ${error.saveClassData.status} - ${error.saveClassData.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  const updateClassData = async (data, id) => {
    try {
      await putApi(`/class/update/${id}`, data);
      setClassId("");
      setClassesFormData({
        class: "",
        section: "",
      });
      setIsAdd(false);
      fetchClasses();
      let successMessage = "Class updated successfully";
      toast.success(successMessage);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  const handleClassChange = (e) => {
    const { name, value } = e.target;
    setClassesFormData({
      ...classesFormData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const submitClassesForm = (e) => {
    e.preventDefault();
    // console.log('Form Data:', classesFormData);
    const validationErrors = validate(classesFormData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (classId) {
        updateClassData(classesFormData, classId);
      } else {
        postClassData(classesFormData);
      }
    }
  };

  const validate = (formData) => {
    const errors = {};
    if (!formData.class) {
      errors.class = "Class is required";
    }
    if (!formData.section) {
      errors.section = "Section is required";
    }
    return errors;
  };

  const getClassData = async (id) => {
    try {
      setIsAdd(true);
      const getClasses = classes.find((classData) => classData._id === id);
      setClassId(id);
      setClassesFormData({
        class: getClasses.class || "",
        section: getClasses.section || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteClassData = async (id) => {
    const willDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });
    if (willDelete.isConfirmed) {
      try {
        const deleteClass = await deleteApi(`/class/delete/${id}`);
        let successMessage = "Class Data has Been Deleted successfully";
        toast.success(successMessage);
        fetchClasses();
        console.log(deleteClass);
      } catch (error) {
        console.log("Error caught:", error);
        let errorMessage = "An error occurred";
        if (error.response) {
          // Extracting the specific message from the server response
          if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (typeof error.response.data === "string") {
            errorMessage = error.response.data;
          } else {
            errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
          }
        } else if (error.request) {
          errorMessage = "No response received from server";
        } else {
          errorMessage = error.message;
        }

        toast.error(errorMessage);
      }
    } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Data is safe :)", "info");
    }
  };


  return (
    <>
      <section>
        <div className="container">
          <ToastContainer />
          <div className="row">
            {!isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row ">
                      <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                        <h5>ALL CLASSES</h5>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                      </div>
                      <div className="col-12 mt-3">
                        <input type="search" placeholder="Search Class" />
                      </div>
                      <div className="col-12 py-3 px-0">
                        <div className="body p-0">
                          <div className="table-responsive">
                            <table className="table table-hover m-0">
                              <thead>
                                <tr className="text-center tableHead">
                                  <th>S.No</th>
                                  <th>Class</th>
                                  <th>Section</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {classes ? (
                                  classes.map((cls, index) => (
                                    <tr>
                                      <td className="text-center">
                                        {index + 1}
                                      </td>
                                      <td className="text-center">
                                        {cls.class}
                                      </td>
                                      <td className="text-center text-uppercase">
                                        {cls.section}
                                      </td>
                                      <td className="text-center">
                                        <i
                                          className="fa-solid fa-pen text-success"
                                          onClick={() =>
                                            getClassData(cls._id)
                                          }
                                        ></i>
                                        <i
                                          className="fa-solid fa-trash text-danger ms-3"
                                          onClick={() =>
                                            deleteClassData(cls._id)
                                          }
                                        ></i>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="4"
                                      className="text-center"
                                    >
                                      No classes found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row stripBg">
                      <div className="col-lg-8 col-md-8 col-sm-8 col-12  p-3">
                        <h5>CLASSES INFORMATION</h5>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                      </div>
                    </div>
                    <form
                      name="classesForm"
                      id="classesForm"
                      onSubmit={submitClassesForm}
                    >
                      <div className="row px-2">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="class" className="form-label">
                              Class<span className="text-danger">*</span>
                            </label>
                            {/* <input autoComplete="off" type="text" name="class" id="class" placeholder="Class" value={classesFormData.class} onChange={handleClassChange} />
                                   */}
                            <select
                              name="class"
                              id="class"
                              value={classesFormData.class}
                              onChange={handleClassChange}
                            >
                              <option value="" disabled>
                                Select the Class
                              </option>
                              <option value="PreSchool">Pre School</option>
                              <option value="Nursery">Nursery</option>
                              <option value="LKG">LKG</option>
                              <option value="UKG">UKG</option>
                              <option value="1st">1st Class</option>
                              <option value="2nd">2nd Class</option>
                              <option value="3rd">3rd Class</option>
                              <option value="4th">4th Class</option>
                              <option value="5th">5th Class</option>
                              <option value="6th">6th Class</option>
                              <option value="7th">7th Class</option>
                              <option value="8th">8th Class</option>
                              <option value="9th">9th Class</option>
                              <option value="10th">10th Class</option>
                            </select>
                            {errors.class && (
                              <p className="text-danger">
                                {errors.class}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label
                              htmlFor="section"
                              className="form-label"
                            >
                              Section
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className="text-uppercase"
                              name="section"
                              id="section"
                              placeholder="Section"
                              value={classesFormData.section}
                              onChange={handleClassChange}
                            />
                            {errors.section && (
                              <p className="text-danger">
                                {errors.section}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 my-5 text-center">
                          <button type="submit" className="allBtns w-100">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </section>
    </>
  )
}

export default Classes
