import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { deleteApi, getApi, postApi, putApi } from '../../utilits/helpers/apiService';
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import Select from 'react-select';

const FeeStructure = () => {
  const { checkAuth } = CheckLogin();
    const [classData, setClassesData] = useState({});
    const [feeStructureData, setFeeStructureData] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedClassList, setSelectedClassList] = useState([]);
    const [isSelect, setIsSelect] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [feeStructureId, setFeeStructureId] = useState('');
    const [feeStructureForm, setFeeStructureForm] = useState({
        class_label: '',
        class_list: '',
        fee_type: '',
        accedmic_year: '',
        one_time_fee: true,
        fee_amount: '',
        one_time_amount: '0',
        term_one_amount: '0',
        term_one_month: 'January',
        term_two_amount: '0',
        term_two_month: 'October',
        term_three_amount: '0',
        term_three_month: 'December',

    })

    const getClasses = async () => {
        try {
            const getClass = await getApi("/class/get");
            const formattedClasses = getClass.response.map(data => ({
                value: data._id,
                label: `${data.class}`
            }));
            setClassesData(formattedClasses);
        } catch (error) {
            console.log("Error fetching classes:", error);
        }
    }

    const getFeeStructures = async () => {
        try {
            const getgetFeeStructuresData = await getApi("/feessturcture/get");
            setFeeStructureData(getgetFeeStructuresData.response.data);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getClasses();
        getFeeStructures();
        checkAuth();
    }, [])



    const feeStructureHandleChange = (event) => {
        if (event.target) {
            // Handle standard input/select change
            const { name, value } = event.target;
            setFeeStructureForm({
                ...feeStructureForm,
                [name]: value
            });
            setErrors({
                ...errors,
                [name]: ''
            });
        }
        else {
            // Handle react-select change
            setSelectedClassList([
                ...event
            ])
            setFeeStructureForm({
                ...feeStructureForm,
                class_list: event
            });
            setErrors({
                ...errors,
                class_list: ''
            });
        }
    };

    const postFeeStructure = async (data) => {
        try {
            await postApi('/feessturcture/create', data);
            setFeeStructureForm({
                class_label: '',
                class_list: '',
                fee_type: '',
                fee_amount: '',
                accedmic_year: '',
                one_time_amount: '',
                term_one_amount: '',
                term_one_month: '',
                term_two_amount: '',
                term_two_month: '',
                term_three_amount: '',
                term_three_month: '',
            })
            let successMessage = "Fee Structure created successfully";
            toast.success(successMessage);
            setSelectedClassList([]);
            setIsAdd(false);
            getFeeStructures();
        } catch (error) {

        }
    }

    const fetchFeeStructureData = async (id) => {
        // console.log(id)
        setIsAdd(true);
        try {
            const feeStructureFetch = feeStructureData.find(feeSteucture => feeSteucture._id === id);
            setFeeStructureId(id);
            const selectedClasses = feeStructureFetch.class_list.map(classId => ({
                value: classId,
                label: classData.find(classItem => classItem.value === classId).label
            }));

            setSelectedClassList(selectedClasses);
            setIsSelect(true);
            console.log(selectedClasses)
            setFeeStructureForm({
                ...feeStructureForm,
                class_label: feeStructureFetch.class_label || '',
                class_list: selectedClasses || [],
                fee_type: feeStructureFetch.fee_type || '',
                fee_amount: feeStructureFetch.fee_amount || '',
                accedmic_year: feeStructureFetch.accedmic_year || '',
                one_time_amount: feeStructureFetch.one_time_amount || '',
                term_one_amount: feeStructureFetch.term_one_amount || '',
                term_one_month: feeStructureFetch.term_one_month || '',
                term_two_amount: feeStructureFetch.term_two_amount || '',
                term_two_month: feeStructureFetch.term_two_month || '',
                term_three_amount: feeStructureFetch.term_three_amount || '',
                term_three_month: feeStructureFetch.term_three_month || '',
            });
            // console.log(feeStructureFetch)
            setFeeStructureForm({
                class_label: feeStructureFetch.class_label || '',
                // class_list: feeStructureFetch.class_list || '',
                fee_type: feeStructureFetch.fee_type || '',
                fee_amount: feeStructureFetch.fee_amount || '',
                accedmic_year: feeStructureFetch.accedmic_year || '',
                one_time_amount: feeStructureFetch.one_time_amount || '',
                term_one_amount: feeStructureFetch.term_one_amount || '',
                term_one_month: feeStructureFetch.term_one_month || '',
                term_two_amount: feeStructureFetch.term_two_amount || '',
                term_two_month: feeStructureFetch.term_two_month || '',
                term_three_amount: feeStructureFetch.term_three_amount || '',
                term_three_month: feeStructureFetch.term_three_month || '',
            })
        }
        catch (error) {
            console.log(error);
        }
    }

    const updFeeStructureData = async (data, id) => {
        try {
            const updateFeeData = await putApi(`/feessturcture/update/${id}`, data);
            setFeeStructureData('');
            setSelectedClassList([]);
            setFeeStructureForm({
                class_label: '',
                class_list: '',
                fee_type: '',
                accedmic_year: '',
                one_time_fee: true,
                fee_amount: '',
                one_time_amount: '',
                term_one_amount: '',
                term_one_month: '',
                term_two_amount: '',
                term_two_month: '',
                term_three_amount: '',
                term_three_month: '',
            })
            let successMessage = "Fee Structure Data has Been Updated successfully";
            toast.success(successMessage);
            setIsAdd(false);
            getFeeStructures();
            console.log(updateFeeData);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    }
    const deleteFeeStructureData = async (id) => {
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            reverseButtons: true
        });
        if (willDelete.isConfirmed) {
            try {
                const deteleUserData = await deleteApi(`/feessturcture/delete/${id}`);
                let successMessage = "Fee Structure Data has Been Deleted successfully";
                toast.success(successMessage);
                getFeeStructures();
                console.log(deteleUserData);
            } catch (error) {
                console.log('Error caught:', error);
                let errorMessage = 'An error occurred';
                if (error.response) {
                    // Extracting the specific message from the server response
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                    }
                } else if (error.request) {
                    errorMessage = 'No response received from server';
                } else {
                    errorMessage = error.message;
                }

                toast.error(errorMessage);
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Fee Structure Data is safe :)", "info");
        }
    }


    const feeStructureSubmitForm = (e) => {
        e.preventDefault();
        // console.log('Form Data:', feeStructureForm);
        const validationErrors = validate(feeStructureForm);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // console.log('Form Data:', feeStructureForm);
            const valuesArray = feeStructureForm.class_list.map(item => item.value);
            console.log(valuesArray)
            const postData = {
                class_label: feeStructureForm.class_label,
                class_list: valuesArray,
                accedmic_year: feeStructureForm.accedmic_year,
                fee_type: feeStructureForm.fee_type,
                fee_amount: feeStructureForm.fee_amount,
                one_time_amount: feeStructureForm.one_time_amount,
                term_one_amount: feeStructureForm.term_one_amount,
                term_one_month: feeStructureForm.term_one_month,
                term_two_amount: feeStructureForm.term_two_amount,
                term_two_month: feeStructureForm.term_two_month,
                term_three_amount: feeStructureForm.term_three_amount,
                term_three_month: feeStructureForm.term_three_month,
                one_time_fee: true,
            };
            if (feeStructureId) {
                updFeeStructureData(postData, feeStructureId);
            } else {
                postFeeStructure(postData);
            }
        }
    }

    const validate = (data) => {
        const errors = {};
        if (!data.class_label) errors.class_label = 'Please Enter the Class Label';
        if (!data.class_list) errors.class_list = 'Please Select the Class List';
        if (!data.accedmic_year) errors.accedmic_year = 'Please Select the Accedmic Year List';
        if (!data.fee_type) errors.fee_type = 'Please Select the Fee Type';
        if (!data.fee_amount) errors.fee_amount = 'Please Enter the Fee Amount';
        if (!data.one_time_amount) errors.one_time_amount = 'Please Enter the One Time Fee';
        if (!data.term_one_amount) errors.term_one_amount = 'Please Enter the Term One Amount';
        if (!data.term_one_month) errors.term_one_month = 'Please Select the 1st Term Month';
        if (!data.term_two_amount) errors.term_two_amount = 'Please Enter the Term Two Amount';
        if (!data.term_two_month) errors.term_two_month = 'Please Select the 2nd Term Month';
        if (!data.term_three_amount) errors.term_three_amount = 'Please Enter the Term Three Amount';
        if (!data.term_three_month) errors.term_three_month = 'Please select the 3rd Term Month';
        return errors;
    };

    return (
        <>
            <section>
                <div className="container">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row">

                        {!isAdd && (
                            <div className="col-xl-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col-8 stripBg p-3">
                                                <h5>FEE STRUCTURE</h5>
                                            </div>
                                            <div className="col-4 stripBg p-3 text-end">
                                                <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <input type="search" placeholder='Search Accounts' />
                                            </div>
                                            <div className="col-12 py-3 px-0">
                                                <div className="body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover m-0">
                                                            <thead>
                                                                <tr className="text-center tableHead">
                                                                    <th>S.No</th>
                                                                    <th>Classes</th>
                                                                    <th>Fee Amount</th>
                                                                    <th>Fee Type</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {feeStructureData ? (
                                                                    feeStructureData.map((feeStructure, index) => (
                                                                        <tr key={index} className="text-center">
                                                                            <td>{index + 1}</td>
                                                                            <td>{feeStructure.class_label}</td>
                                                                            <td>{feeStructure.fee_amount}</td>
                                                                            <td>{feeStructure.fee_type}</td>
                                                                            <td>
                                                                                <i className="fa-solid fa-pen me-3 text-success" onClick={() => fetchFeeStructureData(feeStructure._id)} ></i>
                                                                                <i className="fa-solid fa-trash text-danger" onClick={() => deleteFeeStructureData(feeStructure._id)}></i>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                )
                                                                    :
                                                                    (
                                                                        <tr><td colSpan="4" className="text-center">No Data Found</td></tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row stripBg">
                                            <div className="col-8  p-3">
                                                <h5>FEE STRUCTURE INFORMATION</h5>
                                            </div>
                                            <div className="col-4  p-3 text-end">
                                                <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                                            </div>
                                        </div>
                                        <form name='feeStructureForm' id='feeStructureForm' onSubmit={feeStructureSubmitForm}>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="class_label" className="form-label">Class Label<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="class_label" id="class_label" placeholder="Class Label" onChange={feeStructureHandleChange} value={feeStructureForm.class_label} />
                                                        {errors.class_label && <p className="text-danger">{errors.class_label}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="class_list" className="form-label">Select the Class List<span className="text-danger">*</span></label>
                                                        <Select
                                                            isMulti
                                                            name="class_list"
                                                            value={selectedClassList}
                                                            options={classData}
                                                            className="multiselect"
                                                            classNamePrefix="select"
                                                            onChange={feeStructureHandleChange}
                                                        //  value={feeStructureForm.class_list}
                                                        />
                                                        {errors.class_list && <p className="text-danger">{errors.class_list}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="fee_type" className="form-label">Select the Fee Type<span className="text-danger">*</span></label>
                                                        <select name="fee_type" id="fee_type" onChange={feeStructureHandleChange} value={feeStructureForm.fee_type}>
                                                            <option value="" disabled>Select the Fee Type</option>
                                                            <option value="Tution">Tution</option>
                                                            <option value="Addmission">Addmission</option>
                                                        </select>
                                                        {errors.fee_type && <p className="text-danger">{errors.fee_type}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label htmlFor="fee_type" className="form-label">Select the Accedmic Year<span className="text-danger">*</span></label>
                                                    <input type="date" name="accedmic_year" id="accedmic_year" onChange={feeStructureHandleChange} value={feeStructureForm.accedmic_year} />
                                                    {errors.accedmic_year && <p className="text-danger">{errors.accedmic_year}</p>}
                                                </div>
                                                <div className="col-12 pt-5">
                                                    <h6><u>Total Fee Amont</u></h6>
                                                </div>
                                                <div className="col-lg-6 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="fee_amount" className="form-label">Fee Amount<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="number" name="fee_amount" id="fee_amount" placeholder="Fee Amount" onChange={feeStructureHandleChange} value={feeStructureForm.fee_amount} />
                                                        {errors.fee_amount && <p className="text-danger">{errors.fee_amount}</p>}
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="one_time_amount" className="form-label">One Time Fee<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="number" name="one_time_amount" id="one_time_amount" placeholder="One Time Fee" onChange={feeStructureHandleChange} value={feeStructureForm.one_time_amount} />
                                                        {errors.one_time_amount && <p className="text-danger">{errors.one_time_amount}</p>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="col-12 pt-5">
                                                        <h6><u>Term one Fee</u></h6>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_one_amount" className="form-label">Term One Amount<span className="text-danger">*</span></label>
                                                            <input autoComplete="off" type="number" name="term_one_amount" id="term_one_amount" placeholder="Term One Amount" onChange={feeStructureHandleChange} value={feeStructureForm.term_one_amount} />
                                                            {errors.term_one_amount && <p className="text-danger">{errors.term_one_amount}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_one_month" className="form-label">Term One Month<span className="text-danger">*</span></label>
                                                            {/* <input autoComplete="off" type="text" name="term_one_month" id="term_one_month" placeholder="Term One Month" onChange={feeStructureHandleChange} value={feeStructureForm.term_one_month}/> */}
                                                            <select name="term_one_month" id="term_one_month" onChange={feeStructureHandleChange} value={feeStructureForm.term_one_month}>
                                                                <option value="" disabled>Select the Term One Month</option>
                                                                <option value="January">January</option>
                                                                <option value="February">February</option>
                                                                <option value="March">March</option>
                                                                <option value="April">April</option>
                                                                <option value="May">May</option>
                                                                <option value="June">June</option>
                                                                <option value="July">July</option>
                                                                <option value="August">August</option>
                                                                <option value="September">September</option>
                                                                <option value="October">October</option>
                                                                <option value="November">November</option>
                                                                <option value="December">December</option>
                                                            </select>
                                                            {errors.term_one_month && <p className="text-danger">{errors.term_one_month}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-12 pt-5">
                                                        <h6><u>Term Two Fee</u></h6>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_two_amount" className="form-label">Term Two Amount<span className="text-danger">*</span></label>
                                                            <input autoComplete="off" type="number" name="term_two_amount" id="term_two_amount" placeholder="Term Two Amount" onChange={feeStructureHandleChange} value={feeStructureForm.term_two_amount} />
                                                            {errors.term_two_amount && <p className="text-danger">{errors.term_two_amount}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_two_month" className="form-label">Term Two Month<span className="text-danger">*</span></label>
                                                            {/* <input autoComplete="off" type="text" name="term_two_month" id="term_two_month" placeholder="Term Two Month" onChange={feeStructureHandleChange} value={feeStructureForm.term_two_month}/> */}
                                                            <select name="term_two_month" id="term_two_month" onChange={feeStructureHandleChange} value={feeStructureForm.term_two_month}>
                                                                <option value="" disabled>Select the Term One Month</option>
                                                                <option value="January">January</option>
                                                                <option value="February">February</option>
                                                                <option value="March">March</option>
                                                                <option value="April">April</option>
                                                                <option value="May">May</option>
                                                                <option value="June">June</option>
                                                                <option value="July">July</option>
                                                                <option value="August">August</option>
                                                                <option value="September">September</option>
                                                                <option value="October">October</option>
                                                                <option value="November">November</option>
                                                                <option value="December">December</option>
                                                            </select>
                                                            {errors.term_two_month && <p className="text-danger">{errors.term_two_month}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    <div className="col-12 pt-5">
                                                        <h6><u>Term Three Fee</u></h6>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_three_amount" className="form-label">Term Three Amount<span className="text-danger">*</span></label>
                                                            <input autoComplete="off" type="number" name="term_three_amount" id="term_three_amount" placeholder="Term Three Amount" onChange={feeStructureHandleChange} value={feeStructureForm.term_three_amount} />
                                                            {errors.term_three_amount && <p className="text-danger">{errors.term_three_amount}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 pt-3">
                                                        <div className="input-group">
                                                            <label htmlFor="term_three_month" className="form-label">Term Three Month<span className="text-danger">*</span></label>
                                                            {/* <input autoComplete="off" type="text" name="term_three_month" id="term_three_month" placeholder="Term Three Month" onChange={feeStructureHandleChange} value={feeStructureForm.term_three_month}/> */}
                                                            <select name="term_three_month" id="term_three_month" onChange={feeStructureHandleChange} value={feeStructureForm.term_three_month}>
                                                                <option value="" disabled>Select the Term One Month</option>
                                                                <option value="January">January</option>
                                                                <option value="February">February</option>
                                                                <option value="March">March</option>
                                                                <option value="April">April</option>
                                                                <option value="May">May</option>
                                                                <option value="June">June</option>
                                                                <option value="July">July</option>
                                                                <option value="August">August</option>
                                                                <option value="September">September</option>
                                                                <option value="October">October</option>
                                                                <option value="November">November</option>
                                                                <option value="December">December</option>
                                                            </select>
                                                            {errors.term_three_month && <p className="text-danger">{errors.term_three_month}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 offset-lg-3 col-md-6 offset-md-3 my-5 text-center">
                                                    <button type="submit" className="allBtns w-100">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default FeeStructure
