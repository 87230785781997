import React, { useEffect, useState } from 'react'
import { getApi } from '../../utilits/helpers/apiService';
import * as XLSX from 'xlsx';
import FileDownloadImg from '../../assets/images/file.png'
const Admission = () => {
    const [admissionData, setAdmissionData] = useState([]);
    const [xlData, setXlData] = useState([]);

    const getAdmissionData = async () => {
        try {
            const response = await getApi("/application/get");
            setAdmissionData(response.response);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getAdmissionData();
    }, [])

    useEffect(() => {

        const admssionDataXlPrep = admissionData.map((admission, index) => ({
            S_NO: index + 1,
            student_name: admission.student_name,
            parent_name: admission.parent_name,
            class_seeking_admission_to: admission.class_seeking_admission_to,
            gender: admission.gender,
            message: admission.message,
            mobile_number: admission.mobile_number,
            presently_studying_school: admission.presently_studying_school,
            admission_Date: new Date(admission.created_at).toLocaleDateString('en-GB')
        }));
        setXlData(admssionDataXlPrep);
    }, [admissionData]);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(xlData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Admission Requests");
        XLSX.writeFile(workbook, "admission_requests.xlsx");
    }
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card p-0">
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-10 stripBg p-3">
                                            <h5>Admissions Reqs</h5>
                                        </div>
                                        <div className="col-2 stripBg p-3 text-end">
                                        <button onClick={downloadExcel} className="allBtns"><img src={FileDownloadImg} width="25px" alt="" /></button>
                                        </div>
                                        <div className="col-12 my-3">
                                            <div className="row">
                                                <div className="col-12 py-3 px-0">
                                                    <div className="body p-0">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover m-0">
                                                                <thead>
                                                                    <tr className="text-center tableHead">
                                                                        <th>S.No</th>
                                                                        <th>Parent Name</th>
                                                                        <th>Student Name</th>
                                                                        <th>Gender</th>
                                                                        <th>Mobile Number</th>
                                                                        <th>Cls Seeking</th>
                                                                        <th>Present School</th>
                                                                        <th>Message</th>
                                                                        <th>Enquiries Date</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {admissionData ? (
                                                                        admissionData.map((admission, index) => (
                                                                            <tr>
                                                                                <td className="text-center">
                                                                                    {index + 1}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {admission.parent_name}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {admission.student_name}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {admission.gender}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {admission.mobile_number}
                                                                                </td>

                                                                                <td className="text-center">
                                                                                    {admission.class_seeking_admission_to}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {admission.presently_studying_school}
                                                                                </td>

                                                                                <td className="text-center">
                                                                                    {admission.message}
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    {new Date(admission.created_at).toLocaleDateString('en-GB')}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td
                                                                                colSpan=""
                                                                                className="text-center"
                                                                            >
                                                                                No Admission Req.....
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Admission
