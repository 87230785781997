import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const VerticalGraph = (props) => {
  const data = {
    labels: props.data.labels,
    datasets: props.data.datasets
  };

  if (props.data.dataset && props.data.dataset.length > 0) {
    data.datasets[0] = {
      label: props.data.context,
      data: props.data.dataset[0],
      backgroundColor: 'rgba(6, 38, 102, 1)',
      borderColor: 'rgba(6, 38, 102, 1)',
      borderWidth: 1,
    };
    data.datasets[1] = {
      label: props.data.context,
      data: props.data.dataset[1],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 1,
    };
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: props.data.context2,
      },
    },
  };

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
}

export default VerticalGraph
