import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { Button, Modal } from 'react-bootstrap';
import { CheckLogin } from '../utilits/helpers/ChechLogin';
import sprLogo from '../assets/images/sprLogo.png';

const Sidebar = ({ show, toggleSidebar }) => {

  const handleLinkClick = () => {
    if (window.innerWidth <= 600) {
      toggleSidebar();
    }
  };
  const [showModel, setShowModel] = useState(false);
  const handleClose = () => setShowModel(false);
  const handleShow = () => setShowModel(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { checkAuth } = CheckLogin();
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  useEffect(() => {
    checkAuth();
  }, [])
  const isActive = (path) => location.pathname === path ? 'Links active' : 'Links';
  return (
    <div className={`sidebar ${show ? 'show' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5 d-flex justify-content-center">
          <img src={sprLogo} width="120px" alt="" />
          </div>
        </div>
      <div className="row">
        <div className="col-12 mt-3 LinksCol">
          <Link className={isActive('/')} onClick={handleLinkClick} to="/"><i className="fa-solid fa-house me-3"></i> My School Dashboard</Link>
          <Link className={isActive('/user-management')} onClick={handleLinkClick} to="/user-management"><i className="fa-solid fa-circle-user me-3"></i> User Management</Link>
          <Accordion className='mt-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><i class="fa-solid fa-gear me-3"></i> Configure</Accordion.Header>
              <Accordion.Body className='pt-0'>
                <Link className={isActive('/classes')} onClick={handleLinkClick} to='/classes'><i class="fa-solid fa-people-roof me-3"></i> Class & Sections </Link>
                <Link className={isActive('/subjects')} onClick={handleLinkClick} to='/subjects'><i class="fa-solid fa-book-open me-3"></i> Subjects </Link>
                <Link className={isActive('/fee-structure')} onClick={handleLinkClick} to='/fee-structure'><i class="fa-solid fa-indian-rupee-sign me-3"></i> Fee Structure </Link>
                <Link className={isActive('/transport')} onClick={handleLinkClick} to='/transport'><i class="fa-solid fa-bus me-3"></i> Transport Fee</Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className={isActive('/admissions')} onClick={handleLinkClick} to="/admissions"><i className="fa-solid fa-newspaper me-3"></i> Admissions </Link>
          <Accordion className='mt-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><i class="fa-solid fa-user-graduate me-3"></i> My Students</Accordion.Header>
              <Accordion.Body className='pt-0'>
                <Link className={isActive('/student')} onClick={handleLinkClick} to='/student'><i class="fa-solid fa-clipboard-user me-3"></i> Students </Link>
                <Link className={isActive('/student-attendence')} onClick={handleLinkClick} to='/student-attendence'><i class="fa-solid fa-clipboard-user me-3"></i> Attendence </Link>
                <Link className={isActive('/student-fee-collection')} onClick={handleLinkClick} to='/student-fee-collection'><i class="fa-solid fa-indian-rupee-sign me-3"></i> Fee Collection </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className='mt-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><i class="fa-solid fa-people-group me-3"></i> My Teachers</Accordion.Header>
              <Accordion.Body className='pt-0'>
                <Link className={isActive('/teachers')} onClick={handleLinkClick} to='/teachers'><i class="fa-solid fa-clipboard-user me-3"></i> Teachers </Link>
                <Link className={isActive('/attendence')} onClick={handleLinkClick} to='/attendence'><i class="fa-solid fa-clipboard-user me-3"></i> Attendence </Link>
                <Link className={isActive('/fee-structure')} onClick={handleLinkClick} to='/fee-structure'><i class="fa-solid fa-indian-rupee-sign me-3"></i> Advance </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className='mt-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><i class="fa-solid fa-store me-3"></i> Store</Accordion.Header>
              <Accordion.Body className='pt-0'>
                <Link className={isActive('/fee-structure')} onClick={handleLinkClick} to='/fee-structure'><i class="fa-solid fa-shirt me-3"></i> Uniform </Link>
                <Link className={isActive('/fee-structure')} onClick={handleLinkClick} to='/fee-structure'><i class="fa-solid fa-book me-3"></i> Books </Link>
                <Link className={isActive('/fee-structure')} onClick={handleLinkClick} to='/fee-structure'><i class="fa-solid fa-pen-ruler me-3"></i> Stationary </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className={isActive('/events')} onClick={handleLinkClick} to='/events'><i class="fa-solid fa-people-robbery me-3"></i> Events </Link>
          <Link className={isActive('/petty-cash')} onClick={handleLinkClick} to='/petty-cash'><i class="fa-solid fa-money-bill-1-wave me-3"></i> Petty Cash </Link>
          <Accordion className='mt-2'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><i class="fa-solid fa-comment-dots me-3"></i> Enquiries</Accordion.Header>
              <Accordion.Body className='pt-0'>
                <Link className={isActive('/admission-reqs')} onClick={handleLinkClick} to='/admission-reqs'><i class="fa-solid fa-book-open-reader me-3"></i> Admission Form </Link>
                <Link className={isActive('/contact-reqs')} onClick={handleLinkClick} to='/contact-reqs'><i class="fa-solid fa-address-book me-3"></i> Contact Form </Link>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Link className={isActive('/logout')} onClick={handleShow}><i class="fa-solid fa-right-from-bracket me-3" ></i> Logout </Link>
        </div>
        <Modal centered size="md" show={showModel} onHide={handleClose}>
          <Modal.Header className="text-center" closeButton>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <img src={sprLogo} width="100px" alt="" />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <h4 className="text-center mt-3">Are you sure you want to Logout ?</h4>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-center">
                <button className="allBtns me-3" onClick={handleClose}>Cancel</button>
                <button className="confirmDeleteLogoutBtn" onClick={handleLogout} >Logout</button>
              </div>
            </div>
          </Modal.Body>

        </Modal>
      </div>
      </div>
    </div>
  );
};

export default Sidebar;
