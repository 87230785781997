import React, { useEffect, useState } from 'react'
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { getApi, postApi } from '../../utilits/helpers/apiService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Transport = () => {
  const { checkAuth } = CheckLogin();
  const [transportation, setTransportation] = useState([])
    const [formData, setFormData] = useState({
        class_label: '',
        km_per_rs: '',
        free_kms: '',
    })
    const [errors, setErrors] = useState({})

    const getTransportationData = async () => {
        try {
            const response = await getApi('/transport_fees/get');
            const data = response.response;
            setTransportation(data);
            if (data.length > 0) {
                setFormData(
                    {
                        class_label: data[data.length - 1].class_label,
                        km_per_rs: data[data.length - 1].km_per_rs,
                        free_kms: data[data.length - 1].free_kms,
                    }
                )
            }
        } catch (error) {
            console.error('Error fetching transportation data:', error);
        }

    }

    const postData = async (data) => {
        try {
            const response = await postApi('/transport_fees/create', data);
            setFormData({
                class_label: '',
                km_per_rs: '',
                free_kms: '',
            })
            let successMessage = "Transport Data has been created successfully.";
            toast.success(successMessage);
            getTransportationData();
            console.log(response);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);

        }
    }



    const transportSubmitForm = async (e) => {
        e.preventDefault();
        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            postData(formData);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const validate = (data) => {
        const errors = {};
        if (!data.class_label) errors.class_label = 'Please Enter the Full Name';
        if (!data.km_per_rs) errors.km_per_rs = 'Please Enter the Date of Birth';
        if (!data.free_kms) errors.free_kms = 'Select the Role';
        return errors;
    };


    useEffect(() => {
        getTransportationData()
        checkAuth();
    }, [])

  return (
    <>
       <section className="p-0">
                <div className="container">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row pt-5">
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card p-0">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 stripBg p-3">
                                            <h5>Transportation Information</h5>
                                        </div>
                                    </div>
                                    <form id='transportForm' name='transportForm' onSubmit={transportSubmitForm}>
                                        <div className="row">
                                            <div className="col-lg-4 mt-4">
                                                <div className="input-group">
                                                    <label htmlFor="class_label" className="form-label">Transpotation Lable<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="class_label" id="class_label" placeholder="Transpotation Lable" onChange={handleChange} value={formData.class_label} />
                                                    {errors.class_label && <span className="text-danger">{errors.class_label}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="input-group">
                                                    <label htmlFor="km_per_rs" className="form-label">kilometre Per Rupee<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="km_per_rs" id="km_per_rs" placeholder="kilometre Per Rupee" onChange={handleChange} value={formData.km_per_rs} />
                                                    {errors.km_per_rs && <span className="text-danger">{errors.km_per_rs}</span>}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 mt-4">
                                                <div className="input-group">
                                                    <label htmlFor="free_kms" className="form-label">Free kilometre<span className="text-danger">*</span></label>
                                                    <input autoComplete="off" type="text" name="free_kms" id="free_kms" placeholder="Free kilometre" onChange={handleChange} value={formData.free_kms} />
                                                    {errors.free_kms && <span className="text-danger">{errors.free_kms}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 text-center my-5">
                                                <button type="submit" className="allBtns">Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default Transport
