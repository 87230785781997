import React, { useState, useEffect } from "react";
import {
  getApi,
  postApi
} from "../../utilits/helpers/apiService";
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Typeahead } from "react-bootstrap-typeahead";
// import Swal from "sweetalert2";
// import { Typeahead } from "react-bootstrap-typeahead";

export default function Attendence() {
  const { checkAuth } = CheckLogin();
  const [attendenceData, setAttendenceData] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [markAll, setMarkAll] = useState(false);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0] );
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0] );
  const [teachers, setTeachers] = useState([]);
  const [empType, setEmpType] = useState("")

  const [searchType, setSearchType] = useState("emp_id");
  const[empId, setEmpId] = useState("");
  const [insightsData, setInsightsData] = useState([]);



  const handleChange = (e, index) => {
    const { name, value } = e.target;

    setAttendenceData(prevData => {
      // Create a new array to ensure immutability
      const newData = [...prevData];
      // Update the specific item
      newData[index] = {
        ...newData[index],
        [name]: value
      };
      return newData;
    });
  };

  const onSearchSelect = (text, e) => {
      console.log(text, e);
      setEmpId(text[0]?._id)
  }

  const getTeachers = async () => {
    try {

      let url = "/teacher/filter?page=1&limit=20";

      if (searchType && empId) {
        url += `&${searchType}=${empId}`
      }

      if (empType) {
        url += `&type=${empType}`
      }

      const response = await getApi(url);
      setTeachers([...response.response.data])


    } catch(error) {
      console.log(error);
    }
  }


  const fetchData = async () => {
    try {

      let url = "/attendence/get";
      if (filterDate) {
        url = url + `?start_date=${filterDate}&end_date=${filterDate}`
    }
      const response = await getApi(url);
      console.log(response);
      setAttendenceData([...response.response]);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    checkAuth();
    fetchData();
    getTeachers();
    filterStaffAttendence();
  }, [])

  const createTeachersAttendence = async () => {
    try {
      const response = await postApi("/attendence/create", attendenceData);
      toast.success("Attendence created successfully");
      fetchData();
      console.log(response);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };
  

  const handleCheckboxChange = (e, index) => {
    const { checked } = e.target;

    setAttendenceData(prevData => {
      // Create a new array to ensure immutability
      const newData = [...prevData];
      // Update the specific item's is_present property
      newData[index] = {
        ...newData[index],
        is_present: checked
      };
      return newData;
    });
  }

  const handleMarkAllChange = (e) => {
    e.preventDefault();
    const {checked} = e.target;
    
    const attentendence = attendenceData;
    attentendence.map(item => item.is_present = checked ? true : false);
    setAttendenceData(prevData => {
      const newData = prevData.map(item => ({
        ...item,
        is_present: checked
      }))
      return newData;
    })
    setMarkAll(checked);
  }
  const filterAttendence = () => fetchData();

  const filterStaffAttendence = async () => {

    try {
      let url = "/attendence/insights"

      if (startDate && endDate) {
        url += `?start_date=${startDate}&end_date=${endDate}`
      }

      if (empId) {
        url += `&emp_id=${empId}`;
      }

      if (empType) {
        url += `&type=${empType}`
      } 
      const response = await getApi(url);
      setInsightsData([...response.response]);
    } catch(error) {
      console.log(error);
    }
  }
  return (
    <>
      <section className="accountSec">
        <div className="container">
          <div>
            <ToastContainer />
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mt-5">
              <div className="card p-0">
                <div className="container">
                <div className="row ">
                    <div className="col-12 stripBg p-3">
                      <h5>TEACHERS ATTENDENCES</h5>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            From Date
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>
                          {/* new Date(addData.dob).toISOString().split('T')[0] */}
                          <input type="date" name="startDate" id="startDate" value={startDate} onChange={e => setStartDate(new Date(e.target.value).toISOString().split('T')[0])} />
                          
                          </div>


                          </div>
                    
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            To Date
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>
                          {/* new Date(addData.dob).toISOString().split('T')[0] */}
                          <input type="date" name="endDate" id="endDate" value={endDate} onChange={e => setEndDate(new Date(e.target.value).toISOString().split('T')[0])} />
                          
                          </div>


                          </div>
                    
                        </div>
                        </div>
                        <div className="row">
                          <div  className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3" style={{ marginRight: 5 }}>
                              <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
                                  <option value="emp_id">Emp ID</option>
                                  <option value="full_name">Full Name</option>
                              </select>
                            <div className="input-group">
                              <Typeahead
                                  labelKey={searchType}
                                  onInputChange={onSearchSelect}
                                  onChange={onSearchSelect}
                                  options={teachers}
                                  id="selections-example"
                                  placeholder="Choose a Student..."
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                            <div className="input-group">
                            <div style={{ marginRight: 5 }}>
                            <label className="form-label">
                                Employee Type
                              </label>
                                        <select stype={{ width: 100, marginRight: 5 }} name="empType" id="empType" onChange={e => setEmpType(e.target.value)} value={empType}>
                                          <option value=""></option>
                                            <option value="1">Teaching</option>
                                            <option value="2">Non Teaching</option>
                                        </select>
                                    </div>
                            </div>
                          </div>

                      </div>
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 col-12 pt-3">
                        <button className="btn btn-primary mt-3" onClick={filterStaffAttendence}>Filter Staff Data</button>

                      </div>
                    </div>
                    <div className="col-12 mt-3">
                    <table className="table table-hover m-0">
                            <thead>
                              <tr className="text-center tableHead">
                                <th>S.No</th>
                                <th>Emp Name</th>
                                <th>Emp ID</th>
                                <th>Emp Type</th>
                                <th>Present days</th>
                                <th>Leaves</th>
                                <th>Sick leave</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                insightsData && insightsData.map((insight, index) => (
                                  <tr key={insight?._id}>
                                    <td>{index + 1}</td>
                                    <td>{insight?.full_name}</td>
                                    <td>{insight?.emp_id}</td>
                                    <td>{insight?.type === 1 ? 'Teaching' : 'NON-Teaching'}</td>
                                    <td>{insight?.presentCount}</td>
                                    <td>{insight?.leaveCount}</td>
                                    <td>{insight?.sikLeaveCount}</td>
                                  </tr>
                              ))
                              }
                            </tbody>
                          </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mt-5">
              <div className="card p-0">
                <div className="container">
                  <div className="row ">
                    <div className="col-12 stripBg p-3">
                      <h5>UPDATE ALL ATTENDENCES DATE WISE</h5>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            Select Attendence Date<span className="text-danger">*</span>
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>
                          {/* new Date(addData.dob).toISOString().split('T')[0] */}
                          <input type="date" name="attendenceDate" id="attendenceDate" value={filterDate} onChange={e => setFilterDate(new Date(e.target.value).toISOString().split('T')[0])} />
                          
                          </div>


                          </div>
                    
                        </div>

                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 col-12 pt-3">
                        <button className="btn btn-primary mt-3" onClick={filterAttendence}>Filter Data</button>

                      </div>
                      </div>
                    </div>
                    <div className="col-12 py-3 px-0">
                      <div className="body p-0">
                        <div className="table-responsive">
                          <table className="table table-hover m-0">
                            <thead>
                              <tr className="text-center tableHead">
                                <th>S.No</th>
                                <th>Emp Name</th>
                                <th>Emp ID</th>
                                <th>Emp Type</th>
                                <th>Attendence Date</th>
                                <th>Present <input
                                        type="checkbox"
                                        name="markAll"
                                        id="markAll"
                                        checked={markAll}
                                        onChange={handleMarkAllChange}
                                      /></th>
                                <th>Absent Reason</th>
                                <th>Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {attendenceData ? (
                                attendenceData.map((attendence, index) => (
                                  <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{attendence?.emp_id.full_name}</td>
                                    <td>{attendence?.emp_id.emp_id}</td>
                                    <td>{attendence?.emp_id.type === 1 ? 'Teaching' : 'NON-Teaching'}</td>
                                    <td>{new Date(attendence.attend_date).toLocaleDateString('en-US')}</td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        name="is_present"
                                        id="is_present"
                                        // value={attendence?.is_present}
                                        checked={attendence?.is_present}
                                        onChange={(e) => handleCheckboxChange(e, index)}
                                      />
                                    </td>
                                    <td>

                                    <textarea
                                      autoComplete="off"
                                      type="text"
                                      name="comment"
                                      id="comment"
                                      placeholder="Comment"
                                      onChange={(e) => handleChange(e, index)}
                                      value={attendence.comment}
                                    >
                                      </textarea>
                                    </td>
                                    <td>
                                    <select
                                      name="type"
                                      id="type"
                                      onChange={(e) => handleChange(e, index)}
                                      value={attendence.type}
                                    >
                                      <option value="" disabled>
                                        Select Type
                                      </option>
                                      <option value="duty">Duty</option>
                                      <option value="leave">Leave</option>
                                      <option value="sickleave">Seak leave</option>
                                    </select>
                                    </td>
                                    
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                    No accounts found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 my-5 text-center">
                        <button type="submit" className="allBtns w-100" onClick={createTeachersAttendence}>
                          Submit
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
