import React, { useState, useEffect } from "react";
import {
  deleteApi,
  getApi,
  postApi,
  putApi,
} from "../../utilits/helpers/apiService";
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
export default function TeachersIndex() {
  const { checkAuth } = CheckLogin();
  const [teacherData, setTeacherData] = useState([]);
  const [teacherId, setTeacherId] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [formData, setFormData] = useState({
    full_name: "",
    phone_number: "",
    dob: "",
    type: "",
    gender: "",
    qualification: "",
    image: "",
    experience: "",
    salary_month: "",
    ctc: "",
  });
  const [errors, setErrors] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [pageLimits, setPageLimits] = useState(10);
  const [recordPerPage, setRecordPerPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);


  const handlePagination = (resData) => {
    console.log("my response", resData)
    setPreviousPage(resData.previous);
    setNextPage(resData.next);
    setTotalPages(resData.totalPages);
    setCurrentPage(resData.next ? resData.next - 1 : 1);
    setPageLimits(resData.recordsPerPage);
    setRecordPerPage(resData.recordsPerPage);
    setTotalRecords(resData.total);
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const fetchData = async (page = 1, limit = 10) => {
    try {
      // const response = await getApi("/teacher/get");
      let url = `/teacher/get?page=${page ? page : 1}&limit=${limit}`;
      const response = await getApi(url);
      handlePagination(response.response);
      setTeacherData(response.response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTeacherData = async (id) => {
    setIsAdd(true);
    try {
      const GetTeacherData = teacherData.find((user) => user._id === id);
      console.log("teacherData", GetTeacherData);
      setTeacherId(id);
      setFormData({
        full_name: GetTeacherData.full_name || "",
        phone_number: GetTeacherData.phone_number || "",
        dob: GetTeacherData.dob || "",
        type: GetTeacherData.type || "",
        gender: GetTeacherData.gender || "",
        qualification: GetTeacherData.qualification || "",
        image: GetTeacherData.image || "",
        experience: GetTeacherData.experience || "",
        salary_month: GetTeacherData.salary_month || "",
        ctc: GetTeacherData.ctc || "",
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    checkAuth();
  }, [])

  const postData = async (data) => {
    try {
      const response = await postApi("/teacher/create", data);
      setFormData({
        full_name: "",
        phone_number: "",
        dob: "",
        type: "",
        gender: "",
        qualification: "",
        image: "",
        experience: "",
        salary_month: "",
        ctc: "",
      });
      let successMessage = "New Teacher Has been created successfully";
      toast.success(successMessage);
      setIsAdd(false);
      fetchData();
      console.log(response);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };

  const updateData = async (data, id) => {
    try {
      const updateTeacherData = await putApi(`/teacher/update/${id}`, data);
      setTeacherId('');
      setFormData({
        full_name: "",
        phone_number: "",
        dob: "",
        type: "",
        gender: "",
        qualification: "",
        image: "",
        experience: "",
        salary_month: "",
        ctc: "",
      });
      let successMessage = "User Data has Been Updated successfully";
      toast.success(successMessage);
      setIsAdd(false);
      fetchData();
      console.log(updateTeacherData);
    } catch (error) {
      console.log("Error caught:", error);
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };
  const submitTeacher = (e) => {
    e.preventDefault();
    // console.log('Form Data:', formData);
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (teacherId) {
        updateData(formData, teacherId);
      } else {
        postData(formData);
      }
    }
  };

  const validate = (data) => {
    const errors = {};
    if (!data.full_name) errors.full_name = "Please Enter the Full Name";
    if (!data.phone_number) {
      errors.phone_number = "Please Enter the Phone Number";
    } else if (data.phone_number.length > 12 || data.phone_number.length < 10) {
      errors.phone_number = "Invalid Phone Number";
    }
    if (!data.dob) errors.dob = "Please Enter the Date of Birth";
    if (!data.type) errors.type = "Select the Type";
    if (!data.gender) errors.gender = "Select the Gender";
    if (!data.qualification)
      errors.qualification = "Please Enter the Qualification";
    if (!data.image) errors.image = "Upload the Image";
    if (!data.experience) errors.experience = "Experience if have any in years";
    if (!data.salary_month) errors.salary_month = "Enter the salary per month";
    if (!data.ctc) errors.ctc = "Enter the CTC";
    return errors;
  };

  const deleteAccount = async (id) => {
    const willDelete = await Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this account!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    });
    if (willDelete.isConfirmed) {
      try {
        const deteleteacherData = await deleteApi(`/teacher/delete/${id}`);
        let successMessage = "Teacher Data has Been Deleted successfully";
        toast.success(successMessage);
        fetchData();
        console.log(deteleteacherData);
      } catch (error) {
        console.log("Error caught:", error);
        let errorMessage = "An error occurred";
        if (error.response) {
          // Extracting the specific message from the server response
          if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          } else if (typeof error.response.data === "string") {
            errorMessage = error.response.data;
          } else {
            errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
          }
        } else if (error.request) {
          errorMessage = "No response received from server";
        } else {
          errorMessage = error.message;
        }

        toast.error(errorMessage);
      }
    } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
      Swal.fire("Cancelled", "Your account is safe :)", "info");
    }
  };

  const triggerApiCall = (page) => {
    fetchData(page > 0 ? page : 1, pageLimits)
  }

  return (
    <>
      <section className="accountSec">
        <div className="container">
          <div>
            <ToastContainer />
          </div>

          <div className="row">
            {(!isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row ">
                      <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                        <h5>ALL TEACHERS</h5>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                      </div>
                      <div className="col-12 mt-3">
                        <input type="search" placeholder="Search Accounts" />
                      </div>
                      <div className="col-12 py-3 px-0">
                        <div className="body p-0">
                          <div className="table-responsive">
                            <table className="table table-hover m-0">
                              <thead>
                                <tr className="text-center tableHead">
                                  <th>S.No</th>
                                  <th>Full Name</th>
                                  <th>Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {teacherData ? (
                                  <>
                                    {teacherData.map((teacher, index) => (
                                      <tr key={index} className="text-center">
                                        <td>{index + 1}</td>fetchTeacherData
                                        <td>{teacher.full_name}</td>
                                        <td>{teacher.type === 1 ? 'Teaching' : 'Non-Teaching'}</td>
                                        <td>
                                          <i
                                            className="fa-solid fa-pen me-3 text-success"
                                            onClick={() =>
                                              (teacher._id)
                                            }
                                          ></i>
                                          <i
                                            className="fa-solid fa-trash text-danger"
                                            onClick={() =>
                                              deleteAccount(teacher._id)
                                            }
                                          ></i>
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td className='text-center'>Pages: {totalPages}</td>
                                      <td className='text-center'>Per pages: {recordPerPage}</td>
                                      <td className='d-flex align-items-center justify-content-center'>Records: {totalRecords} <input className='form-control text-center ms-3' style={{ width: '75px' }} type="number" value={pageLimits} onChange={(e) => setPageLimits(e.target.value)} /></td>

                                      {/* <td className=''>
                                        
                                      </td> */}
                                      <td className='text-center'>
                                        {
                                          previousPage > 0 ? (
                                            <a className="link-info" onClick={() => triggerApiCall(previousPage)} >{`<<`} {previousPage}</a>
                                          ) :
                                            <a className="link link-info" >{`<<`} 0 </a>
                                        }
                                        <span style={{ margin: '0 10px', fontWeight: 'bold', color: 'red', border: '1px solid red', padding: '5px', borderRadius: '5px' }}>
                                          {currentPage}
                                        </span>
                                        {
                                          nextPage ? (
                                            <a className="link link-info" onClick={() => triggerApiCall(nextPage)}>{nextPage} {`>>`}</a>
                                          ) :
                                            <a className="link-info" >{`>>`} </a>
                                        }
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <tr>
                                    <td colSpan="4" className="text-center">
                                      No accounts found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {isAdd && (
              <div className="col-xl-12 col-lg-12 mt-5">
                <div className="card p-0">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                        <h5>TEACHERS INFORMATION</h5>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                      </div>
                    </div>
                    <form
                      name="teacherForm"
                      id="teacherForm"
                      onSubmit={submitTeacher}
                    >
                      <div className="row px-2">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="full_name" className="form-label">
                              Full Name<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="full_name"
                              id="full_name"
                              placeholder="Full Name"
                              onChange={handleChange}
                              value={formData.full_name}
                            />
                            {errors.full_name && (
                              <p className="text-danger">{errors.full_name}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="type" className="form-label">
                              Select the Type
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              name="type"
                              id="type"
                              onChange={handleChange}
                              value={formData.type}
                            >
                              <option value="">Select the Type</option>
                              <option value="1">Teaching</option>
                              <option value="2">Non Teaching</option>
                            </select>
                            {errors.type && (
                              <p className="text-danger">{errors.type}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="dob" className="form-label">
                              Date of Birth<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="date"
                              name="dob"
                              id="dob"
                              placeholder="Date of Birth"
                              onChange={handleChange}
                              value={formData.dob}
                            />
                            {errors.dob && (
                              <p className="text-danger">{errors.dob}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="gender" className="form-label">
                              Select the Gender
                              <span className="text-danger">*</span>
                            </label>
                            <select
                              name="gender"
                              id="gender"
                              onChange={handleChange}
                              value={formData.gender}
                            >
                              <option value="" disabled>
                                Select the Gender
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Others">Others</option>
                            </select>
                            {errors.gender && (
                              <p className="text-danger">{errors.gender}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="qualification" className="form-label">
                              Qualification<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="qualification"
                              id="qualification"
                              placeholder="Qualification"
                              onChange={handleChange}
                              value={formData.qualification}
                            />
                            {errors.qualification && (
                              <p className="text-danger">
                                {errors.qualification}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="phone_number" className="form-label">
                              Phone Number<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="number"
                              name="phone_number"
                              id="phone_number"
                              placeholder="Phone Number"
                              onChange={handleChange}
                              value={formData.phone_number}
                            />
                            {errors.phone_number && (
                              <p className="text-danger">{errors.phone_number}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="image" className="form-label">
                              Image<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="image"
                              id="image"
                              placeholder="Image"
                              onChange={handleChange}
                              value={formData.image}
                            />
                            {errors.image && (
                              <p className="text-danger">{errors.image}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="experience" className="form-label">
                              Experience<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="experience"
                              id="experience"
                              placeholder="Experience...Eg.000"
                              onChange={handleChange}
                              value={formData.experience}
                            />
                            {errors.experience && (
                              <p className="text-danger">{errors.experience}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="salary_month" className="form-label">
                              Monthly Salary<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="salary_month"
                              id="salary_month"
                              placeholder="Enter Monthly Salary...Eg.000"
                              onChange={handleChange}
                              value={formData.salary_month}
                            />
                            {errors.salary_month && (
                              <p className="text-danger">{errors.salary_month}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                          <div className="input-group">
                            <label htmlFor="ctc" className="form-label">
                              CTC<span className="text-danger">*</span>
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              name="ctc"
                              id="ctc"
                              placeholder="Enter CTC...Eg.000"
                              onChange={handleChange}
                              value={formData.ctc}
                            />
                            {errors.ctc && (
                              <p className="text-danger">{errors.ctc}</p>
                            )}
                          </div>
                        </div>
                        <div className="col-12 my-5 text-center">
                          <button type="submit" className="allBtns w-100">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
