// import React, { useEffect, useState } from 'react';
// import { ToastContainer } from 'react-toastify';
// import { getApi } from '../../utilits/helpers/apiService';
// import { CheckLogin } from '../../utilits/helpers/ChechLogin';
// import { Typeahead } from 'react-bootstrap-typeahead';
// import * as XLSX from 'xlsx';
// import FileDownloadImg from '../../assets/images/file.png'

// const StudentList = () => {

//     const [admissions, setAdmissions] = useState([]);
//     const [classList, setClassList] = useState([]);
//     const [classId, setClassId] = useState('');
//     const [searchType, setSearchType] = useState("student_id");
//     const [studentId, setStudentId] = useState('');
//     const { checkAuth } = CheckLogin();
//     const [xlData, setXlData] = useState([]);

//     const [currentPage, setCurrentPage] = useState(1);
//     const [totalPages, setTotalPages] = useState(0);
//     const [previousPage, setPreviousPage] = useState(0);
//     const [nextPage, setNextPage] = useState(1);
//     const [pageLimits, setPageLimits] = useState(10);
//     const [recordPerPage, setRecordPerPage] = useState(0);
//     const [totalRecords, setTotalRecords] = useState(0);


//     const handlePagination = (resData) => {
//         setPreviousPage(resData.previous);
//         setNextPage(resData.next);
//         setTotalPages(resData.totalPages);
//         setCurrentPage(resData.next ? resData.next - 1 : 1);
//         setPageLimits(resData.recordsPerPage);
//         setRecordPerPage(resData.recordsPerPage);
//         setTotalRecords(resData.total);
//     }

//     useEffect(() => {
//         const studentsXlPrepare = admissions.map((students, index) => ({
//             S_No: index + 1,
//             student_id: students.student_id,
//             student_fullname: students.student_fullname,
//             class_id: students.class_id.class,
//             section: students.class_id.section,
//             father_mobile_no: students.father_mobile_no,
//             mother_mobile_no: students.mother_mobile_no,
//             admission_date: new Date(students.created_at).toLocaleDateString('en-GB')
//         }));
//         setXlData(studentsXlPrepare);
//     }, [admissions]);

//     const downloadExcel = () => {
//         const worksheet = XLSX.utils.json_to_sheet(xlData);
//         const workbook = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
//         XLSX.writeFile(workbook, "Students.xlsx");
//     }

//     const fetchData = async (student_id = null, page = 1, limit = 10) => {
//         try {
//             let url = `/admission/students?page=${page ? page : 1}&limit=${limit}`;
//             // let url = '/admission/students?limit=50';
//             // if (student_id) {
//             //     url += `&${searchType}=${student_id}`;
//             // }
//             // // if (searchType) {
//             // //     url += `&${searchType}=${studentId}`;
//             // // }
//             // if (classId) {
//             //     url += `&class_id=${classId}`
//             // }
//             if (classId) {
//                 url += `&class_id=${classId}`
//             }
//             if (studentId) {
//                 url += `&${searchType}=${student_id}`;
//             }
//             const response = await getApi(url);
//             handlePagination(response.response);
//             setAdmissions(response.response.data);
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     const onSearchSelect = (text, e) => {
//         setStudentId(text[0]?._id)
//         fetchData(text[0]?.[searchType]);
//     }

//     const fetchClassData = async () => {
//         try {
//             const response = await getApi("/class/get");
//             setClassList(response.response);
//         } catch (error) {
//             console.log(error);
//         }
//     };

//     useEffect(() => {
//         fetchData();
//         fetchClassData();
//         checkAuth();
//     }, [])

//     useEffect(() => {
//         fetchData(classId, studentId);
//     }, [classId, studentId])

//     const onClassChange = (e) => {
//         let changeClassDataId = e.target.value
//         fetchData(changeClassDataId);
//         setClassId(e.target.value);
//     }

//     const resetData = () => {
//         setClassId('');
//         setStudentId('');
//         fetchData();
//         const selectElement = document.getElementById('selectClass');
//         selectElement.selectedIndex = 0;
//     }

//     const triggerApiCall = (page) => {
//         fetchData(page > 0 ? page : 1, pageLimits)
//     }

//     return (
//         <div>
//             <section className='accountSec'>
//                 <div className="container">
//                     <div>
//                         <ToastContainer />
//                     </div>
//                     <div className="row">
//                         <div className="col-xl-12 col-lg-12 mt-5">
//                             <div className="card p-0">
//                                 <div className="container">
//                                     <div className="row ">
//                                         <div className="col-8 stripBg p-3">
//                                             <h5>ALL STUDENTS</h5>
//                                         </div>
//                                         <div className="col-4 stripBg p-3 text-end">
//                                             {/* <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button> */}
//                                             <button onClick={downloadExcel} className="allBtns"><img src={FileDownloadImg} width="25px" alt="" /></button>
//                                         </div>
//                                         <div className="col-12 mt-3">
//                                             <div className='d-flex'>
//                                                 <div className='me-3'>
//                                                     <select className="form-select" id='selectClass' aria-label="Default select example" onChange={onClassChange}>
//                                                         <option selected>Class</option>
//                                                         {classList ? (
//                                                             classList.map((classs, index) => (
//                                                                 <option key={index} value={classs._id}>{classs.class}</option>
//                                                             ))
//                                                         )
//                                                             : (
//                                                                 <option value="">No class found</option>
//                                                             )}
//                                                     </select>
//                                                 </div>
//                                                 <div style={{ marginRight: 5 }}>
//                                                     <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
//                                                         <option value="student_id">Student ID</option>
//                                                         <option value="student_fullname">Full Name</option>
//                                                     </select>
//                                                 </div>
//                                                 <div>
//                                                     <Typeahead
//                                                         labelKey={searchType}
//                                                         onInputChange={onSearchSelect}
//                                                         onChange={onSearchSelect}
//                                                         options={admissions}
//                                                         id="selections-example"
//                                                         placeholder="Choose a Student..."
//                                                     />
//                                                 </div>
//                                                 <div>
//                                                     <button className='allBtns ms-4' onClick={resetData}>Reset</button>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                         <div className="col-12 py-3 px-0">
//                                             <div className="body p-0">
//                                                 <div className="table-responsive">
//                                                     <table className="table table-hover m-0">
//                                                         <thead>
//                                                             <tr className="text-center tableHead">
//                                                                 <th>S.No</th>
//                                                                 <th>Student ID</th>
//                                                                 <th>Full Name</th>
//                                                                 <th>Class</th>
//                                                             </tr>
//                                                         </thead>
//                                                         <tbody>
//                                                             {admissions ? (
//                                                                 <>
//                                                                     {admissions.map((admision, index) => (
//                                                                         <tr key={index} className="text-center">
//                                                                             <td>{index + 1}</td>
//                                                                             <td>{admision.student_id}</td>
//                                                                             <td>{admision.student_fullname}</td>
//                                                                             <td>{admision.class_id?.class}</td>
//                                                                         </tr>
//                                                                     ))}

//                                                                     <tr>
//                                                                         <td className='text-center'>Pages: {totalPages}</td>
//                                                                         <td className='text-center'>Per pages: {recordPerPage} <span className='ms-3'>Records: {totalRecords}</span></td>
//                                                                         <td className='d-flex align-items-center justify-content-center'>
//                                                                             <input className='form-control text-center' style={{ width: '75px' }} type="number" value={pageLimits} onChange={(e) => setPageLimits(e.target.value)} />
//                                                                         </td>
//                                                                         <td className='text-center'>
//                                                                             {
//                                                                                 previousPage > 0 ? (
//                                                                                     <a className="link-info" onClick={() => triggerApiCall(previousPage)} >{`<<`} {previousPage}</a>
//                                                                                 ) :
//                                                                                     <a className="link link-info" >{`<<`} 0 </a>
//                                                                             }
//                                                                             <span style={{ margin: '0 10px', fontWeight: 'bold', color: 'red', border: '1px solid red', padding: '5px', borderRadius: '5px' }}>
//                                                                                 {currentPage}
//                                                                             </span>
//                                                                             {
//                                                                                 nextPage ? (
//                                                                                     <a className="link link-info" onClick={() => triggerApiCall(nextPage)}>{nextPage} {`>>`}</a>
//                                                                                 ) :
//                                                                                     <a className="link-info" >{`>>`} </a>
//                                                                             }
//                                                                         </td>

//                                                                     </tr>
//                                                                 </>
//                                                             )
//                                                                 : (
//                                                                     <tr>
//                                                                         <td colSpan="4" className="text-center">No Admissions found</td>
//                                                                     </tr>
//                                                                 )}
//                                                         </tbody>
//                                                     </table>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// }

// export default StudentList;










import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { getApi } from '../../utilits/helpers/apiService';
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as XLSX from 'xlsx';
import FileDownloadImg from '../../assets/images/file.png'
import ReactPaginate from 'react-paginate';

const StudentList = () => {
    const [admissions, setAdmissions] = useState([]);
    const [classList, setClassList] = useState([]);
    const [classId, setClassId] = useState('');
    const [searchType, setSearchType] = useState("student_id");
    const [studentId, setStudentId] = useState('');
    const { checkAuth } = CheckLogin();
    const [xlData, setXlData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const [pageLimits, setPageLimits] = useState(10);
    const [recordPerPage, setRecordPerPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const [page, setPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)

    // const handlePagination = (resData) => {
    //     setPreviousPage(resData.previous);
    //     setNextPage(resData.next);
    //     setTotalPages(resData.totalPages);
    //     setCurrentPage(resData.next ? resData.next - 1 : 1);
    //     setPageLimits(resData.recordsPerPage);
    //     setRecordPerPage(resData.recordsPerPage);
    //     setTotalRecords(resData.total);
    // }

    useEffect(() => {
        const studentsXlPrepare = admissions.map((students, index) => ({
            S_No: index + 1,
            student_id: students.student_id,
            student_fullname: students.student_fullname,
            class_id: students.class_id.class,
            section: students.class_id.section,
            father_mobile_no: students.father_mobile_no,
            mother_mobile_no: students.mother_mobile_no,
            admission_date: new Date(students.created_at).toLocaleDateString('en-GB')
        }));
        setXlData(studentsXlPrepare);
    }, [admissions]);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(xlData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Students");
        XLSX.writeFile(workbook, "Students.xlsx");
    }

    const fetchData = async (student_id = null, page = 1, limit = 10) => {
        try {
            let url = `/admission/students?page=${page ? page : 1}&limit=${pageLimits}`;
            if (classId) {
                url += `&class_id=${classId}`
            }
            if (studentId) {
                url += `&${searchType}=${studentId}`;
            }
            const response = await getApi(url);
            setRecordsPerPage(response.response.recordsPerPage);
            setTotalPages(response.response.totalPages);
            setAdmissions(response.response.data);
            setTotalRecords(response.response.total);
        } catch (error) {
            console.log(error);
        }
    };

    const onSearchSelect = (text, e) => {
        setStudentId(text[0]?.[searchType])
        fetchData();
    }

    const fetchClassData = async () => {
        try {
            const response = await getApi("/class/get");
            setClassList(response.response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchClassData();
        checkAuth();
    }, [])

    useEffect(() => {
        fetchData(null, page, recordsPerPage);
       
    }, [page, recordsPerPage])

    useEffect(() => {
        fetchData();
    }, [classId, studentId])

    const onClassChange = (e) => {
        let changeClassDataId = e.target.value
        fetchData(changeClassDataId);
        setClassId(e.target.value);
    }

    const resetData = () => {
        setClassId('');
        setStudentId('');
        const selectElement = document.getElementById('selectClass');
        selectElement.selectedIndex = 0;
        setPageLimits(10);
        setRecordPerPage(10);
        setPage(1);
        fetchData();
    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    const changePageLimit = (event) => {
        setRecordsPerPage(event.target.value);
        setPage(1);
        setPageLimits(event.target.value);
    }

    return (
        <div>
            <section className='accountSec'>
                <div className="container">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 mt-5">
                            <div className="card p-0">
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-8 stripBg p-3">
                                            <h5>ALL STUDENTS</h5>
                                        </div>
                                        <div className="col-4 stripBg p-3 text-end">
                                            {/* <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button> */}
                                            <button onClick={downloadExcel} className="allBtns"><img src={FileDownloadImg} width="25px" alt="" /></button>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className='d-flex'>
                                                <div className='me-3'>
                                                    <select className="form-select" id='selectClass' aria-label="Default select example" onChange={onClassChange}>
                                                        <option selected value="">All</option>
                                                        {classList ? (
                                                            classList.map((classs, index) => (
                                                                <option key={index} value={classs._id}>{classs.class}</option>
                                                            ))
                                                        )
                                                            : (
                                                                <option value="">No class found</option>
                                                            )}
                                                    </select>
                                                </div>
                                                <div style={{ marginRight: 5 }}>
                                                    <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
                                                        <option value="student_id">Student ID</option>
                                                        <option value="student_fullname">Full Name</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <Typeahead
                                                        labelKey={searchType}
                                                        onInputChange={onSearchSelect}
                                                        onChange={onSearchSelect}
                                                        options={admissions}
                                                        id="selections-example"
                                                        placeholder="Choose a Student..."
                                                    />
                                                </div>
                                                <div>
                                                    <button className='allBtns ms-4' onClick={resetData}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 py-3 px-0">
                                            <div className="body p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-hover m-0">
                                                        <thead>
                                                            <tr className="text-center tableHead">
                                                                <th>S.No</th>
                                                                <th>Student ID</th>
                                                                <th>Full Name</th>
                                                                <th>Class</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {admissions ? (
                                                                <>
                                                                    {admissions.map((admision, index) => (
                                                                        <tr key={index} className="text-center">
                                                                            <td>{index + 1}</td>
                                                                            <td>{admision.student_id}</td>
                                                                            <td>{admision.student_fullname}</td>
                                                                            <td>{admision.class_id?.class} - {admision.class_id?.section}</td>
                                                                        </tr>
                                                                    ))}

                                                                    <tr>
                                                                        <td className='text-center'>Pages: {totalPages}</td>
                                                                        <td className='text-center'>Per pages: {recordsPerPage}</td>
                                                                        <td className='text-center'>Records: {totalRecords}</td>
                                                                        <td className='d-flex align-items-center justify-content-center'>
                                                                            <input className='form-control text-center' style={{ width: '75px' }} type="number" value={pageLimits} onChange={changePageLimit} />
                                                                            {/* onChange={(e) => setPageLimits(e.target.value)} */}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                                : (
                                                                    <tr>
                                                                        <td colSpan="4" className="text-center">No Admissions found</td>
                                                                    </tr>
                                                                )}
                                                        </tbody>
                                                    </table>

                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={handlePageClick}
                                                        pageRangeDisplayed={5}
                                                        pageCount={totalPages}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        containerClassName="pagination"
                                                        activeClassName="active"
                                                        pageClassName="page-item"
                                                        pageLinkClassName="page-link"
                                                        previousClassName="page-item"
                                                        previousLinkClassName="page-link"
                                                        nextClassName="page-item"
                                                        nextLinkClassName="page-link"
                                                        breakClassName="page-item"
                                                        breakLinkClassName="page-link"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default StudentList;
