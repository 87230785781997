import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import Header from '../Components/Header';
import Dashboard from './Dashboard';
import Accounts from './Accounts/Index';
import Classes from './Config/Classes';
import FeeStructure from '../pages/Config/FeeStructure';
import Transport from '../pages/Config/Transport';
import Subjects from '../pages/Config/Subjects';
import Admissions from './Admissions/List';
import AdmissionPrintBill from './Admissions/AdmissionPrintBill';
import Teachers from './Teachers/TeachersIndex';
import Attendence from './Teachers/Attendence';
import StudentFeeCollection from './Students/FeeCollections';
import StudentAttendence from './Admissions/StudentAttendence';
import PrintBill from './Students/PrintBill';
import Login from './Login';
import StudentList from './Students';
import AdmissionReqs from '../pages/Enquiries/Admission';
import ContactReq from '../pages/Enquiries/Contact';
import PettyCash from './PettyCash';
import PettyCashPrintScreen from './PettyCashPrintScreen';
import Events from './Events';

const hiddenPaths = ['/login', '/print-bill', '/admission-print-bill', '/petty-cash-print-screen'];

function App() {
  const [showSidebar, setShowSidebar] = useState(true);
  const location = useLocation();
  const hideHeaderAndSidebar = hiddenPaths.includes(location.pathname);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };


  const getPageTitle = (path) => {
    switch (path) {
      case '/': return 'My School Dashboard';
      case '/user-management': return 'User Management';
      case '/classes': return 'Class & Sections';
      case '/subjects': return 'Subjects';
      case '/fee-structure': return 'Fee Structure';
      case '/transport': return 'Transport';
      case '/admissions': return 'Admissions';
      case '/teachers': return 'Teachers';
      case '/attendence': return 'Attendence';
      case '/student': return 'Students';
      case '/student-fee-collection': return 'Student Fee Collection';
      case '/student-attendence': return 'Student Attendence';
      case '/admission-reqs': return 'Admission Requisitions';
      case '/contact-reqs': return 'Contact Requisitions';
      case '/petty-cash': return 'Petty Cash';
      case '/events': return 'Events';
      default: return 'Page Title'; 
    }
  };

  const pageTitle = getPageTitle(location.pathname);

  return (
    <div className={`App ${showSidebar ? 'show-sidebar' : 'hide-sidebar'} ${hideHeaderAndSidebar ? 'hide-header-and-sidebar' : ''}`}>
      {!hideHeaderAndSidebar && (
        <>
          <Sidebar show={showSidebar} toggleSidebar={toggleSidebar} />
        </>
      )}
      <div className="content-wrapper">
        <div className='main-content'>
          {!hideHeaderAndSidebar && (
            <Header toggleSidebar={toggleSidebar} showSidebar={showSidebar} pageTitle={pageTitle}  />
          )}
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<Dashboard />} />
            <Route path='/user-management' element={<Accounts />} />
            <Route path='/classes' element={<Classes />} />
            <Route path='/subjects' element={<Subjects />} />
            <Route path='/fee-structure' element={<FeeStructure />} />
            <Route path='/transport' element={<Transport />} />
            <Route path='/admissions' element={<Admissions />} />
            <Route path='/teachers' element={<Teachers />} />
            <Route path='/attendence' element={<Attendence />} />
            <Route path='/student' element={<StudentList />} />
            <Route path='/student-fee-collection' element={<StudentFeeCollection />} />
            <Route path='/student-attendence' element={<StudentAttendence />} />
            <Route path="/print-bill" element={<PrintBill />} />
            <Route path="/admission-print-bill" element={<AdmissionPrintBill />} />
            <Route path="/admission-reqs" element={<AdmissionReqs />} />
            <Route path="/contact-reqs" element={<ContactReq />} />
            <Route path="/petty-cash" element={<PettyCash />} />
            <Route path="/petty-cash-print-screen" element={<PettyCashPrintScreen />} />
            <Route path="/events" element={<Events />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
