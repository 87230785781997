import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { CheckLogin } from '../utilits/helpers/ChechLogin';
import sprLogo from '../assets/images/sprLogo.png';
import SprStrip from '../assets/images/SprStrip.png';
const PettyCashPrintScreen = () => {

    const { checkAuth } = CheckLogin();
    const location = useLocation();
    const { pettyCashData } = location.state || {};

    useEffect(() => {
        checkAuth();
        console.log("bbcbbbcbb", pettyCashData);
    }, []);

    const handlePrint = () => {
        window.print();
    };
    const presentDate = new Date();
    const expenseDate = new Date(pettyCashData.expence_date);
    const formattedDate = expenseDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

    return (
        <>
            <style>
                {`
                    @media print {
                        .print-hidden {
                            display: none;
                        }
                        .no-print {
                            display: none;
                        }
                    }
                `}
            </style>
            <section className='printBillSec'>
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src={SprStrip} width="60%" height="100px" alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="row mt-2">
                                        <div className="col-lg-12 text-end">
                                            <h6>Date: <u className='ms-2'>{presentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</u></h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <h6>Expense Date : <span className='text-dark'>{formattedDate} </span> </h6>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <h6>Expense Description : <span className='text-dark'>{pettyCashData.description}</span></h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr className='m-3' />


                                        <table className="table text-center mx-3">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>S.NO</th>
                                                    <th>PARTICULAR</th>
                                                    <th>AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Expense Amount</td>
                                                    <td>{pettyCashData.amount}/-</td>
                                                </tr>
                                                <tr className='mt-4'>
                                                    <td colspan="2" className='text-center'><h5>TOTAL AMOUNT</h5></td>
                                                    <td><h5>{pettyCashData.amount}/-</h5></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="row mt-3">
                                            <div className="col-12 text-end">
                                                <p className=''>For SRI SPR GLOBAL SCHOOL</p>
                                            </div>
                                            <div className="col-12 text-end mt-5 pt-2">
                                                <p className=''>Signature</p>
                                            </div>
                                        </div>

                                        <div className="row print-hidden">
                                            <div className="col-6 text-end">
                                                <button className='allBtns' onClick={handlePrint}>Print</button>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/petty-cash"><button className='allBtns'>Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PettyCashPrintScreen
