import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { deleteApi, getApi, postApi, putApi } from '../../utilits/helpers/apiService';
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { useNavigate } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as XLSX from 'xlsx';
import FileDownloadImg from '../../assets/images/file.png'
import ReactPaginate from 'react-paginate';

const List = () => {
    const { checkAuth } = CheckLogin();
    const [admissionData, setAdmissionData] = useState([]);
    const [admissionId, setAdmissionId] = useState();
    const [previousEducation, setPreviousEducation] = useState([]);
    const [previousEducationId, setPreviousEducationId] = useState('');
    const [admissions, setAdmissions] = useState([]);
    const [presentClasses, setPresentClasses] = useState({});
    const [netTutionFee, setNetTutionFee] = useState([]);
    const [admissionFee, setAdmissionFee] = useState([]);
    const [transportKmPerRs, setTransportKmPerRs] = useState(0);
    const [transportFreeKms, setTransportFreeKms] = useState(0);
    const [netTutionFeeAmount, setNetTutionFeeAmount] = useState({});
    const [admissionFeeAmount, setAdmissionFeeAmount] = useState({});
    const [discountAmount, setDiscountAmount] = useState(0);
    const [transportKmts, setTransportKmts] = useState(0);
    const [netTutionFeeId, setNetTutionFeeId] = useState('');
    const [admissionFeeId, setAdmissionFeeId] = useState('');
    // pagination variables
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [previousPage, setPreviousPage] = useState(0);
    const [nextPage, setNextPage] = useState(1);
    const [pageLimits, setPageLimits] = useState(10);
    const [recordPerPage, setRecordPerPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [classId, setClassId] = useState('');
    const [classList, setClassList] = useState([]);
    const [searchType, setSearchType] = useState("student_id");
    const [studentId, setStudentId] = useState('');

    const [isAdd, setIsAdd] = useState(false);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        joining_date: '',
        class_id: '',
        first_language: '',
        student_fullname: '',
        gender: '',
        religion: '',
        caste: '',
        dob: '',
        tc_no: '',
        student_aadhar_no: '',
        email: '',
        father_name: '',
        mother_name: '',
        father_mobile_no: '',
        father_aadhar_no: '',
        mother_mobile_no: '',
        mother_aadhar_no: '',
        communication_address: '',
        admission_fee: '',
        net_tution_fee: '',
        transport_fee: '',
        discount_fee: 0,
        payment_type: '',
        total_fee: 0,
        total_fee_in_words: '',
        father_edu_qualification: '',
        father_occupation: '',
        father_annual_income: '',
        mother_edu_qualification: '',
        mother_occupation: '',
        mother_annual_income: '',
        guardian_edu_qualification: '',
        guardian_occupation: '',
        guardian_annual_income: '',
        personal_identification_of_student_marks: '',
        bro_sis_are_studying: false,
        parent_are_employed_in_spr: false,
        parent_emp_id: '',
        decleration: false,
    })
    const [xlData, setXlData] = useState([]);
    const [page, setPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10)
    const [oneTimeTermAmount, setOneTimeTermAmount] = useState(0);

    useEffect(() => {
        const admissionXlPrepare = admissionData.map((admission, index) => ({
            S_No: index + 1,
            student_id: admission.student_id,
            student_fullname: admission.student_fullname,
            class_id: admission.class_id.class,
            section: admission.class_id.section,
            net_tution_fee: admission.net_tution_fee,
            payment_type: admission.payment_type,
            total_fee: admission.total_fee,
            total_fee_in_words: admission.total_fee_in_words,
            transport_kms: admission.transport_kms,
            transport_total_kms: admission.transport_total_kms,
            father_name: admission.father_name,
            mother_name: admission.mother_name,
            father_occupation: admission.father_occupation,
            father_mobile_no: admission.father_mobile_no,
            mother_mobile_no: admission.mother_mobile_no,
            joining_date: new Date(admission.joining_date).toLocaleDateString('en-GB'),
            father_annual_income: admission.father_annual_income,
            mother_annual_income: admission.mother_annual_income,
            mother_edu_qualification: admission.mother_edu_qualification,
            first_language: admission.first_language,
            gender: admission.gender,
            email: admission.email,
            dob: admission.dob,
            discount_fee: admission.discount_fee,
            communication_address: admission.communication_address,
            personal_identification_of_student_marks: admission.personal_identification_of_student_marks,
            religion: admission.religion,
            student_aadhar_no: admission.student_aadhar_no,
            tc_no: admission.tc_no,
        }));
        setXlData(admissionXlPrepare);
    }, [admissions]);

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(xlData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Admissions");
        XLSX.writeFile(workbook, "Admissions.xlsx");
    }

    const fetchData = async (student_id = null, page = 1) => {
        try {
            let url = `/admission/filter?page=${page ? page : 1}&limit=${recordsPerPage}`
            if (classId) {
                url += `&class_id=${classId}`
            }
            if (studentId) {
                url += `&${searchType}=${studentId}`;
            }
            const response = await getApi(url); 
            setAdmissions(response.response.data);
            setAdmissionData(response.response.data);
            setRecordsPerPage(response.response.recordsPerPage);
            setTotalPages(response.response.totalPages);
            setTotalRecords(response.response.total);
        } catch (error) {
            console.log(error);
        }
    };

     

    useEffect(() => {
        checkAuth();
        fetchClassData();
    }, []);

    useEffect(() => {
        fetchData(null, page, recordsPerPage);
    }, [page, recordsPerPage]);

    const handlePreviouEducationForm = () => {
        const year = document.getElementById('year').value;
        const prev_class = document.getElementById('prev_class').value;
        const name_of_school = document.getElementById('name_of_school').value;
        const place = document.getElementById('place').value;
        const medium_instruction = document.getElementById('medium_instruction').value;

        if (year === '' && prev_class === '' && name_of_school === '' && place === '' && medium_instruction === '') {
            const prevErrorMessage = "All fields are required";
            toast.error(prevErrorMessage);
        } else {
            if (previousEducationId) {
                const updatedEducation = [...previousEducation];
                if (previousEducationId) {
                    updatedEducation[previousEducationId] = { year, prev_class, name_of_school, place, medium_instruction };
                    setPreviousEducation(updatedEducation);
                    setPreviousEducationId('');
                } else {
                    toast.error("Invalid index");
                }
                document.getElementById('year').value = '';
                document.getElementById('prev_class').value = '';
                document.getElementById('name_of_school').value = '';
                document.getElementById('place').value = '';
                document.getElementById('medium_instruction').value = '';
                toast.success("Education updated successfully");
            } else {
                console.log("i am in muzamill")
                setPreviousEducation(
                    [...previousEducation, { year, prev_class, name_of_school, place, medium_instruction }]
                )
                document.getElementById('year').value = '';
                document.getElementById('prev_class').value = '';
                document.getElementById('name_of_school').value = '';
                document.getElementById('place').value = '';
                document.getElementById('medium_instruction').value = '';
            }

        }
    }
    const getPreviousEducationData = async (index) => {
        const record = previousEducation[index];
        setPreviousEducationId(index);
        document.getElementById('year').value = record.year;
        document.getElementById('prev_class').value = record.prev_class;
        document.getElementById('name_of_school').value = record.name_of_school;
        document.getElementById('place').value = record.place;
        document.getElementById('medium_instruction').value = record.medium_instruction;
    }
    const deletePreviousEducationData = async (index) => {
        const updatedEducation = [...previousEducation];
        if (previousEducationId >= 0 && previousEducationId < updatedEducation.length) {
            updatedEducation.splice(index, 1);
            setPreviousEducation(updatedEducation);
            setPreviousEducationId('');
        } else {
            toast.error("Invalid index");
        }
    }
    const getClassesData = async () => {
        try {
            const getClasses = await getApi("/class/get");
            setPresentClasses(getClasses.response);
        } catch (error) {
            console.log("Error fetching classes:", error);
        }
    }

    const getNetTutionFeeData = async () => {
        try {
            const tutionFee = await getApi("/feessturcture/get?fee_type=Tution");
            setNetTutionFee(tutionFee.response.data);
        } catch (error) {
            console.log("Error fetching classes:", error);
        }
    }
    const getAdmissionFeeData = async () => {
        try {
            const admissionFee = await getApi("/feessturcture/get?fee_type=Addmission");
            setAdmissionFee(admissionFee.response.data);
            // console.log("admission Amount",admissionFee.response)
        } catch (error) {
            console.log("Error fetching classes:", error);
        }
    }

    const getTransportFeeData = async () => {
        try {
            const transportFee = await getApi("/transport_fees/get");
            const kmPerRs = transportFee.response[0].km_per_rs;
            const kmFree = transportFee.response[0].free_kms;
            setTransportKmPerRs(kmPerRs);
            setTransportFreeKms(kmFree);
        } catch (error) {
            console.log("Error fetching classes:", error);
        }
    }
    // const getDiscountData = async () => {
    //     try {
    //         const Discount = await getApi("/feessturcture/get?fee_type=fee_discount");
    //         setDiscount(Discount.response);
    //     } catch (error) {
    //         console.log("Error fetching classes:", error);
    //     }
    // }
    useEffect(() => {
        fetchData();
    }, [classId, studentId])

    const fetchClassData = async () => {
        try {
            const response = await getApi("/class/get");
            console.log(response.data)
            setClassList(response.response);
        } catch (error) {
            console.log(error);
        }
    };

    const onClassChange = (e) => {
        console.log("my class Change", e.target.value);
        setClassId(e.target.value);
    }
    const onSearchSelect = (text, e) => {
        console.log(text, e);
        setStudentId(text[0]?.[searchType])
        fetchData();
    }

    const onChangeAdmissionFee = (e) => {
        const admissionFeeId = e.target.value;
        setAdmissionFeeId(admissionFeeId);
        const getAdmissionFee = admissionFee.find(fee => fee._id === admissionFeeId);
        if (getAdmissionFee) {
            setAdmissionFeeAmount(getAdmissionFee);
        } else {
            console.error(`Admission fee with ID ${admissionFeeId} not found.`);
        }
    };

    const onChangeTutionFee = (e) => {
        const tutionFeeId = e.target.value;
        setNetTutionFeeId(tutionFeeId);
        const getTutionFee = netTutionFee.find(fee => fee._id === tutionFeeId);
        if (getTutionFee) {
            setNetTutionFeeAmount(getTutionFee);
        } else {
            console.error(`Admission fee with ID ${admissionFeeId} not found.`);
        }
    };

    // const onChangeTransportFee = (e) => {
    //     const transportFeeId = e.target.value;
    //     setTransportFeeId(transportFeeId);
    //     const getTransportFee = transportFee.find(fee => fee._id === transportFeeId);
    //     if(getTransportFee){
    //         setTransportFeeAmount(getTransportFee);
    //     } else {
    //         console.error(`Admission fee with ID ${transportFeeId} not found.`);
    //     }
    // };



    const calculateFeeStructure = () => {
        const getTransmortKmts = parseInt(transportKmts);
        let calTransportFeeAmount;

        if (getTransmortKmts <= transportFreeKms) {
            calTransportFeeAmount = 0;
        } else {
            calTransportFeeAmount = getTransmortKmts * transportKmPerRs;
            console.log(calTransportFeeAmount)
        }

        const getTotalFee = (formData?.payment_type === 'onetime' ? netTutionFeeAmount?.one_time_amount : netTutionFeeAmount?.fee_amount) + admissionFeeAmount?.fee_amount + calTransportFeeAmount - discountAmount;
        // document.getElementById('total_fee').value = getTotalFee;
        // transportFeeAmount?.fee_amount
        setFormData({ ...formData, total_fee: getTotalFee, transport_total_kms: calTransportFeeAmount });
        console.log(getTotalFee)
    }

    useEffect(() => {
        calculateFeeStructure()
    }, [netTutionFeeAmount,
        admissionFeeAmount,
        // transportFeeAmount,
        transportKmts,
        discountAmount])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name === 'discount_fee') {
            setDiscountAmount(value);
        }
        if (name === 'transport_kms') {
            setTransportKmts(value);
        }
        
        if(admissionId){            
            const getTutionFee = netTutionFee.find(fee => fee._id === netTutionFeeId);
            if(name === 'payment_type'){
                if(value === 'onetime'){
                    setOneTimeTermAmount(getTutionFee.one_time_amount)
                }else{
                    setOneTimeTermAmount(getTutionFee.fee_amount)
                }
            }
        }else{
            if(name === 'payment_type'){
                if(value === 'onetime'){
                    setOneTimeTermAmount(netTutionFeeAmount.one_time_amount)
                }else{
                    setOneTimeTermAmount(netTutionFeeAmount.fee_amount)
                }
            }
        }
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const postData = async (formData) => {
        try {
            // const dataArray = [data];
            // const combinedData = [...previousEducation, ...dataArray];
            const combinedData = {
                ...formData,
                name_previus_school_attended: previousEducation,
                net_tution_fee: netTutionFeeId,
                admission_fee: admissionFeeId
            };
            console.log(combinedData)
            const response = await postApi('/admission/create', combinedData);
            setFormData({
                joining_date: '',
                class_id: '',
                first_language: '',
                student_fullname: '',
                gender: '',
                religion: '',
                caste: '',
                dob: '',
                tc_no: '',
                student_aadhar_no: '',
                email: '',
                father_name: '',
                mother_name: '',
                father_mobile_no: '',
                father_aadhar_no: '',
                mother_mobile_no: '',
                mother_aadhar_no: '',
                communication_address: '',
                admission_fee: '',
                net_tution_fee: '',
                transport_fee: '',
                discount_fee: '',
                payment_type: '',
                total_fee: '',
                total_fee_in_words: '',
                name_previus_school_attended: '',
                father_edu_qualification: '',
                father_occupation: '',
                father_annual_income: '',
                mother_edu_qualification: '',
                mother_occupation: '',
                mother_annual_income: '',
                guardian_edu_qualification: '',
                guardian_occupation: '',
                guardian_annual_income: '',
                personal_identification_of_student_marks: '',
                bro_sis_are_studying: '',
                parent_are_employed_in_spr: '',
                parent_emp_id: '',
                decleration: false,
            })
            let successMessage = "New Admission has been saved successfully";
            toast.success(successMessage);
            setIsAdd(false);
            fetchData();
            console.log(response);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);

        }
    }

    const deleteAdmissionData = async (id) => {
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this Data!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            reverseButtons: true
        });
        if (willDelete.isConfirmed) {
            try {
                const deleteAdmission = await deleteApi(`/admission/delete/${id}`);
                let successMessage = "Admission Data has Been Deleted successfully";
                toast.success(successMessage);
                fetchData();
                console.log(deleteAdmission);
            } catch (error) {
                console.log('Error caught:', error);
                let errorMessage = 'An error occurred';
                if (error.response) {
                    // Extracting the specific message from the server response
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (typeof error.response.data === 'string') {
                        errorMessage = error.response.data;
                    } else {
                        errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                    }
                } else if (error.request) {
                    errorMessage = 'No response received from server';
                } else {
                    errorMessage = error.message;
                }

                toast.error(errorMessage);
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Admission Data is safe :)", "info");
        }
    }

    const fetchAdmissionData = async (id) => {
        setIsAdd(true);
        try {
            const addData = admissionData.find(admission => admission._id === id);
            setAdmissionId(id);
            setAdmissionFeeId(addData.admission_fee);
            setNetTutionFeeId(addData.net_tution_fee);
            const broSisAreStudying = addData.bro_sis_are_studying ? "true" : "false";
            const parentAreEmployedInSPR = addData.parent_are_employed_in_spr ? "true" : "false";
            const formattedJoiningDate = addData.joining_date ? new Date(addData.joining_date).toISOString().split('T')[0] : '';
            const formattedDob = addData.dob ? new Date(addData.dob).toISOString().split('T')[0] : '';
            setFormData({
                joining_date: formattedJoiningDate,
                class_id: addData.class_id._id || '',
                first_language: addData.first_language || '',
                student_fullname: addData.student_fullname || '',
                gender: addData.gender || '',
                religion: addData.religion || '',
                caste: addData.caste || '',
                dob: formattedDob || '',
                tc_no: addData.tc_no || '',
                student_aadhar_no: addData.student_aadhar_no || '',
                email: addData.email || '',
                father_name: addData.father_name || '',
                mother_name: addData.mother_name || '',
                father_mobile_no: addData.father_mobile_no || '',
                father_aadhar_no: addData.father_aadhar_no || '',
                mother_mobile_no: addData.mother_mobile_no || '',
                mother_aadhar_no: addData.mother_aadhar_no || '',
                communication_address: addData.communication_address || '',
                admission_fee: addData.admission_fee || '',
                net_tution_fee: addData.net_tution_fee || '',
                transport_fee: addData.transport_fee || '',
                discount_fee: addData.discount_fee || '',
                payment_type: addData.payment_type || '',
                total_fee: addData.total_fee || '',
                total_fee_in_words: addData.total_fee_in_words || '',
                transport_total_kms: addData.transport_total_kms || '',
                transport_kms: addData.transport_kms || '',
                father_edu_qualification: addData.father_edu_qualification || '',
                father_occupation: addData.father_occupation || '',
                father_annual_income: addData.father_annual_income || '',
                mother_edu_qualification: addData.mother_edu_qualification || '',
                mother_occupation: addData.mother_occupation || '',
                mother_annual_income: addData.mother_annual_income || '',
                guardian_edu_qualification: addData.guardian_edu_qualification || '',
                guardian_occupation: addData.guardian_occupation || '',
                guardian_annual_income: addData.guardian_annual_income || '',
                personal_identification_of_student_marks: addData.personal_identification_of_student_marks || '',
                bro_sis_are_studying: broSisAreStudying,
                parent_are_employed_in_spr: parentAreEmployedInSPR,
                parent_emp_id: addData.parent_emp_id || '',
                decleration: addData.decleration || '',
            });
            setPreviousEducation(addData.name_previus_school_attended);
            const getTutionFee = netTutionFee.find(fee => fee._id === addData.net_tution_fee);
            if(addData.payment_type === "onetime"){
                setOneTimeTermAmount(getTutionFee.one_time_amount)
            }else if(addData.payment_type === "termwise"){
                setOneTimeTermAmount(getTutionFee.fee_amount)
            }else{
                setOneTimeTermAmount(0)
            }
        } catch (error) {
            console.log(error);
        }
    }
     
    const updateData = async (data, id) => {
        try {
            // 
            const combinedData = {
                ...formData,
                name_previus_school_attended: previousEducation,
                net_tution_fee: netTutionFeeId,
                admission_fee: admissionFeeId
            };
            const updateAdmissionData = await putApi(`/admission/update/${id}`, combinedData);
            setAdmissionId('');
            setFormData({
                joining_date: '',
                class_id: '',
                first_language: '',
                student_fullname: '',
                gender: '',
                religion: '',
                caste: '',
                dob: '',
                tc_no: '',
                student_aadhar_no: '',
                email: '',
                father_name: '',
                mother_name: '',
                father_mobile_no: '',
                father_aadhar_no: '',
                mother_mobile_no: '',
                mother_aadhar_no: '',
                communication_address: '',
                admission_fee: '',
                net_tution_fee: '',
                transport_fee: '',
                discount_fee: '',
                payment_type: '',
                total_fee: '',
                total_fee_in_words: '',
                father_edu_qualification: '',
                father_occupation: '',
                father_annual_income: '',
                mother_edu_qualification: '',
                mother_occupation: '',
                mother_annual_income: '',
                guardian_edu_qualification: '',
                guardian_occupation: '',
                guardian_annual_income: '',
                personal_identification_of_student_marks: '',
                bro_sis_are_studying: '',
                parent_are_employed_in_spr: '',
                parent_emp_id: '',
                decleration: false,
            })
            setPreviousEducation([]);
            let successMessage = "Admission Data Has Been Updated Successfully";
            toast.success(successMessage);
            setIsAdd(false);
            fetchData();
            console.log(updateAdmissionData);
        } catch (error) {
            console.log('Error caught:', error);
            let errorMessage = 'An error occurred';
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === 'string') {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = 'No response received from server';
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    }

    const admissionSubmitForm = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
        setFormData({
            ...formData,
            admission_fee: admissionFeeId ? admissionFeeId : '',
            net_tution_fee: netTutionFeeId ? netTutionFeeId : '',
        })
        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            if (admissionId) {
                updateData(formData, admissionId);
            } else {
                postData(formData);
            }

        }
    };

    useEffect(() => {
        getClassesData();
        getNetTutionFeeData();
        getAdmissionFeeData();
        getTransportFeeData();
    }, [])


    const navigate = useNavigate();
    const handleDownloadClick = (item) => {
        // console.log("MY Item", item)
        navigate('/admission-print-bill', { state: { admissionData: item } });
    };

    const validate = (data) => {
        const errors = {};
        if (!data.joining_date) errors.joining_date = 'Please Select the Date of Joining';
        if (!data.class_id) errors.class_id = 'Please Select the Class';
        if (!data.first_language) errors.first_language = 'Please Enter the First Language';
        if (!data.student_fullname) errors.student_fullname = 'Please Enter the Student Name';
        if (!data.gender) errors.gender = 'Please Select the Gender';
        if (!data.religion) errors.religion = 'Please Enter the Religion';
        if (!data.caste) errors.caste = 'Please Enter the Caste';
        if (!data.dob) errors.dob = 'Please Enter the Date of Birth';
        if (!data.tc_no) errors.tc_no = 'Please Enter the TC Number';
        if (!data.student_aadhar_no) errors.student_aadhar_no = 'Please Enter the Student Aadhar Number';
        if (!data.email) errors.email = 'Please Enter the Email';
        if (!data.father_name) errors.father_name = 'Please Enter the Father Name';
        if (!data.mother_name) errors.mother_name = 'Please Enter the Mother Name';
        if (!data.father_mobile_no) errors.father_mobile_no = 'Please Enter the Father Mobile Number';
        // if (!data.father_aadhar_no) errors.father_aadhar_no = 'Please Enter the Father Aadhar Number';
        // if (!data.mother_mobile_no) errors.mother_mobile_no = 'Please Enter the Mother Mobile Number';
        // if (!data.mother_aadhar_no) errors.mother_aadhar_no = 'Please Enter the Mother Aadhar Number';
        if (!data.communication_address) errors.communication_address = 'Please Enter the Communication Address';
        // if (!data.admission_fee) errors.admission_fee = 'Please Select the Admission Fee';
        // if (!data.net_tution_fee) errors.net_tution_fee = 'Please Select the Net Tution Fee';
        // if (!data.transport_fee) errors.transport_fee = 'Please Select the Transport Fee';
        // if (!data.discount_fee) errors.discount_fee = 'Please Select the Discount Fee';
        if (!data.payment_type) errors.payment_type = 'Please Enter the Payment Type';
        if (!data.transport_kms) errors.transport_kms = 'Please Enter the Transport Kilometer';
        // if (!data.transport_total_kms) errors.transport_total_kms = 'Please Enter the Total Transport Fee';
        // if (!data.total_fee) errors.total_fee = 'Please Enter the Total Fee';
        if (!data.total_fee_in_words) errors.total_fee_in_words = 'Please Enter the Total Fee in Words';
        // if (!data.father_edu_qualification) errors.father_edu_qualification = 'Please Enter the Father Occupation';
        // if (!data.father_occupation) errors.father_occupation = 'Please Enter the Father Occupation';
        // if (!data.father_annual_income) errors.father_annual_income = 'Please Enter the Father Annual Income';
        // if (!data.mother_edu_qualification) errors.mother_edu_qualification = 'Please Enter the Mother Education Qualification';
        // if (!data.mother_occupation) errors.mother_occupation = 'Please Enter the Mother Occupation';
        // if (!data.mother_annual_income) errors.mother_annual_income = 'Please Enter the Mother Annual Income';
        // if (!data.guardian_edu_qualification) errors.guardian_edu_qualification = 'Please Enter the Guardian Education Qualification';
        // if (!data.guardian_occupation) errors.guardian_occupation = 'Please Enter the Guardian Occupation';
        // if (!data.guardian_annual_income) errors.guardian_annual_income = 'Please Enter the Guardian Annual Income';
        if (!data.personal_identification_of_student_marks) errors.personal_identification_of_student_marks = 'Please Enter the Personal Identification of Student Marks';
        if (!data.bro_sis_are_studying) errors.bro_sis_are_studying = 'Please Enter the Bro/Sis Are Studying';
        if (!data.parent_are_employed_in_spr) errors.parent_are_employed_in_spr = 'Please Enter the Parent Are Employed In SPR';
        if (!data.decleration) errors.decleration = 'Please Enter the Decleration';
        return errors;
    };

    const triggerApiCall = (page) => {
        fetchData(page > 0 ? page : 1, pageLimits)
    }


    const resetData = () => {
        setClassId('');
        setStudentId('');
        const selectElement = document.getElementById('selectClass');
        selectElement.selectedIndex = 0;
        setPageLimits(10);
        setRecordPerPage(10);
        setPage(1);
        fetchData();
    }

    const handlePageClick = (event) => {
        setPage(event.selected + 1);
    };

    const changePageLimit = (event) => {
        setRecordsPerPage(event.target.value);
        setPage(1);
        setPageLimits(event.target.value);
    }

    return (
        <>
            <section className='accountSec'>
                <div className="container">
                    <div>
                        <ToastContainer />
                    </div>
                    <div className="row">
                        {!isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row ">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                                                <h5>ALL ADMISSION</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                            <button onClick={downloadExcel} className="allBtns"><img src={FileDownloadImg} width="25px" alt="" /></button>  <button className="hideAndShowBtns" onClick={() => setIsAdd(true)}>Add +</button>
                                            </div>
                                            <div className="col-12 mt-3">
                                            <div className='d-flex'>
                                                <div className='me-3'>
                                                    <select className="form-select" id='selectClass' aria-label="Default select example" onChange={onClassChange}>
                                                        <option selected>Class</option>
                                                        {classList ? (
                                                            classList.map((classs, index) => (
                                                                <option key={index} value={classs._id}>{classs.class}</option>
                                                            ))
                                                        )
                                                            : (
                                                                <option value="">No class found</option>
                                                            )}
                                                    </select>
                                                </div>
                                                <div style={{ marginRight: 5 }}>
                                                    <select stype={{ width: 100, marginRight: 5 }} name="searchBy" id="searchBy" onChange={e => setSearchType(e.target.value)} value={searchType}>
                                                        <option value="student_id">Student ID</option>
                                                        <option value="student_fullname">Full Name</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <Typeahead
                                                        labelKey={searchType}
                                                        onInputChange={onSearchSelect}
                                                        onChange={onSearchSelect}
                                                        options={admissions}
                                                        id="selections-example"
                                                        placeholder="Choose a Student..."
                                                    />
                                                </div>
                                                <div>
                                                    <button className='allBtns ms-4' onClick={resetData}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-12 py-3 px-0">
                                                <div className="body p-0">
                                                    <div className="table-responsive">
                                                        <table className="table table-hover m-0">
                                                            <thead>
                                                                <tr className="text-center tableHead">
                                                                    <th>S.No</th>
                                                                    <th>Student ID</th>
                                                                    <th>Full Name</th>
                                                                    <th>Class</th>
                                                                    <th>Gender</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {admissions ? (
                                                                    <>
                                                                        {admissions.map((admision, index) => (
                                                                            <tr key={index} className="text-center">
                                                                                <td>{index + 1}</td>
                                                                                <td>{admision.student_id}</td>
                                                                                <td>{admision.student_fullname}</td>
                                                                                <td>{admision.class_id?.class}-{admision.class_id?.section}</td>
                                                                                <td>{admision.gender}</td>
                                                                                <td>
                                                                                    <i className="fa-solid fa-pen me-3 text-success" onClick={() => fetchAdmissionData(admision._id)} ></i>
                                                                                    <i className="fa-solid fa-trash text-danger" onClick={() => deleteAdmissionData(admision._id)}></i>
                                                                                    <i className="fa fa-download ms-3 text-warning" onClick={() => handleDownloadClick(admision)}></i>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                        
                                                                        <tr>
                                                                        <td className='text-center'>Pages: {totalPages}</td>
                                                                        <td className='text-center'>Per pages: {recordsPerPage}</td>
                                                                        <td className='text-center'>Records: {totalRecords}</td>
                                                                            
                                                                            <td className='d-flex align-items-center justify-content-center'>
                                                                                <input className='form-control text-center' style={{ width: '75px' }} type="number" value={pageLimits} onChange={changePageLimit} />
                                                                            </td>
                                                                             
                                                                            <td className='text-center'></td>
                                                                            <td className='text-center'></td>
                                                                        </tr>
                                                                    </>
                                                                )

                                                                    : (
                                                                        <tr>
                                                                            <td colSpan="4" className="text-center">No Admissions found</td>
                                                                        </tr>
                                                                    )}
                                                            </tbody>
                                                        </table>
                                                        <ReactPaginate
                                                            breakLabel="..."
                                                            nextLabel=">"
                                                            onPageChange={handlePageClick}
                                                            pageRangeDisplayed={5}
                                                            pageCount={totalPages}
                                                            previousLabel="<"
                                                            renderOnZeroPageCount={null}
                                                            containerClassName="pagination"
                                                            activeClassName="active"
                                                            pageClassName="page-item"
                                                            pageLinkClassName="page-link"
                                                            previousClassName="page-item"
                                                            previousLinkClassName="page-link"
                                                            nextClassName="page-item"
                                                            nextLinkClassName="page-link"
                                                            breakClassName="page-item"
                                                            breakLinkClassName="page-link"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAdd && (
                            <div className="col-xl-12 col-lg-12 mt-5">
                                <div className="card p-0">
                                    <div className="container">
                                        <div className="row stripBg">
                                            <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                                                <h5>ADMISSION INFORMATION</h5>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                                                <button className="hideAndShowBtns" onClick={() => setIsAdd(false)}>Back</button>
                                            </div>
                                        </div>
                                        <form name='admissionForm' id='admissionForm' onSubmit={admissionSubmitForm} >
                                            <div className="row px-2">
                                                <div className="col-12 mt-3">
                                                    <h6><u>Joining Details</u></h6>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="joining_date" className="form-label">Joining Date<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="date" name="joining_date" id="joining_date" placeholder="Joining Date" value={formData.joining_date} onChange={handleChange} />
                                                                {errors.joining_date && <span className="text-danger">{errors.joining_date}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="class_id" className="form-label">Class<span className="text-danger">*</span></label>
                                                                <select name="class_id" id="selectedClassChangeFunction" value={formData.class_id} onChange={handleChange}>
                                                                    <option value="" disabled>Select Class</option>
                                                                    {Array.isArray(presentClasses) && presentClasses.map((item, index) => (
                                                                        <option key={index} value={item._id}>{item.class}-{item.section}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.class_id && <span className="text-danger">{errors.class}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="first_language" className="form-label">First Language<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="first_language" id="first_language" placeholder="First Language" value={formData.first_language} onChange={handleChange} />
                                                                {errors.first_language && <span className="text-danger">{errors.first_language}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="student_fullname" className="form-label">Student Full Name<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="student_fullname" id="student_fullname" placeholder="Student Full Name" value={formData.student_fullname} onChange={handleChange} />
                                                                {errors.student_fullname && <span className="text-danger">{errors.student_fullname}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="gender" className="form-label">Select the Gender<span className="text-danger">*</span></label>
                                                                <select name="gender" id="gender" value={formData.gender} onChange={handleChange}>
                                                                    <option value="" disabled>Select the Gender</option>
                                                                    <option value="Male">Male</option>
                                                                    <option value="Female">Fe Male</option>
                                                                    <option value="Other">Other</option>
                                                                </select>
                                                                {errors.gender && <span className="text-danger">{errors.gender}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="religion" className="form-label">Religion<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="religion" id="religion" placeholder="Religion" value={formData.religion} onChange={handleChange} />
                                                                {errors.religion && <span className="text-danger">{errors.religion}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="caste" className="form-label">Caste<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="caste" id="caste" placeholder="Caste" value={formData.caste} onChange={handleChange} />
                                                                {errors.caste && <span className="text-danger">{errors.caste}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="dob" className="form-label">DoB<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="date" name="dob" id="dob" placeholder="DoB" value={formData.dob} onChange={handleChange} />
                                                                {errors.dob && <span className="text-danger">{errors.dob}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="tc_no" className="form-label">Tc.No<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="tc_no" id="tc_no" placeholder="Tc.No" value={formData.tc_no} onChange={handleChange} />
                                                                {errors.tc_no && <span className="text-danger">{errors.tc_no}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-5">
                                                    <h6><u>Personal Details</u></h6>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="student_aadhar_no" className="form-label">Student Aadhar Number<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="student_aadhar_no" id="student_aadhar_no" placeholder="Student Aadhar Number" value={formData.student_aadhar_no} onChange={handleChange} />
                                                                {errors.student_aadhar_no && <span className="text-danger">{errors.student_aadhar_no}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="email" className="form-label">Email<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="email" name="email" id="email" placeholder="Email" value={formData.email} onChange={handleChange} />
                                                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="father_name" className="form-label">Father Name<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="father_name" id="father_name" placeholder="Father Name" value={formData.father_name} onChange={handleChange} />
                                                                {errors.father_name && <span className="text-danger">{errors.father_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="mother_name" className="form-label">Mother Name<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="mother_name" id="mother_name" placeholder="Mother Name" value={formData.mother_name} onChange={handleChange} />
                                                                {errors.mother_name && <span className="text-danger">{errors.mother_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="father_mobile_no" className="form-label">Father Mobile Number<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="father_mobile_no" id="father_mobile_no" placeholder="Father Mobile Number" value={formData.father_mobile_no} onChange={handleChange} />
                                                                {errors.father_mobile_no && <span className="text-danger">{errors.father_mobile_no}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="father_aadhar_no" className="form-label">Father Aadhar Number<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="father_aadhar_no" id="father_aadhar_no" placeholder="Father Aadhar Number" value={formData.father_aadhar_no} onChange={handleChange} />
                                                                {errors.father_aadhar_no && <span className="text-danger">{errors.father_aadhar_no}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="mother_mobile_no" className="form-label">Mother Mobile Number<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="mother_mobile_no" id="mother_mobile_no" placeholder="Mother Mobile Number" value={formData.mother_mobile_no} onChange={handleChange} />
                                                                {errors.mother_mobile_no && <span className="text-danger">{errors.mother_mobile_no}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="mother_aadhar_no" className="form-label">Mother Aadhar Number<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="mother_aadhar_no" id="mother_aadhar_no" placeholder="Mother Aadhar Number" value={formData.mother_aadhar_no} onChange={handleChange} />
                                                                {errors.mother_aadhar_no && <span className="text-danger">{errors.mother_aadhar_no}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="input-group">
                                                                <label htmlFor="communication_address" className="form-label">Communication Address<span className="text-danger">*</span></label>
                                                                <textarea name="communication_address" id="communication_address" value={formData.communication_address} onChange={handleChange}></textarea>
                                                                {errors.communication_address && <span className="text-danger">{errors.communication_address}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 mt-5">
                                                    <h6><u>Fee Structure Details</u></h6>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="admission_fee" className="form-label">Admission Fee<span className="text-danger">*</span></label>
                                                                <select name="admission_fee" id="admission_fee" value={admissionFeeId} onChange={onChangeAdmissionFee}>
                                                                    <option value="" disabled>Select The Admission Fee</option>
                                                                    {Array.isArray(admissionFee) && admissionFee.map((item, index) => (
                                                                        <option key={index} value={item._id}>{item.class_label} - {item.fee_amount}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.admission_fee && <span className="text-danger">{errors.admission_fee}</span>}
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="net_tution_fee" className="form-label">Net Tution Fee<span className="text-danger">*</span></label>
                                                                <select name="net_tution_fee" id="net_tution_fee" value={netTutionFeeId} onChange={onChangeTutionFee}>
                                                                    <option value="" disabled>Select The Net Tution Fee</option>
                                                                    {Array.isArray(netTutionFee) && netTutionFee.map((item, index) => (
                                                                        <option key={index} value={item._id}>{item.class_label} - {item.fee_amount}</option>
                                                                    ))}
                                                                </select>
                                                                {errors.net_tution_fee && <span className="text-danger">{errors.net_tution_fee}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="payment_type" className="form-label">Payment Type<span className="text-danger">*</span></label>
                                                                <select name="payment_type" id="payment_type" value={formData.payment_type} onChange={handleChange}>
                                                                    <option value="" disabled>Select The Discount</option>
                                                                    <option value="onetime">One Time</option>
                                                                    <option value="termwise">Term Wise</option>
                                                                </select>
                                                                {errors.payment_type && <span className="text-danger">{errors.payment_type}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="oneTimeTermAmount" className="form-label">One Time Or Term Amount<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="oneTimeTermAmount" id="oneTimeTermAmount" placeholder="One Time Or Term Amount" value={oneTimeTermAmount} onChange={handleChange} readOnly />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="discount_fee" className="form-label">Discount<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="discount_fee" id="discount_fee" placeholder="Discount amount" value={formData.discount_fee} onChange={handleChange} />

                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="transport_kms" className="form-label">Transport Kilometers<span className="text-danger"></span></label>
                                                                <input type="number" name="transport_kms" id="transport_kms" placeholder="Transport Kilometers" value={formData.transport_kms} onChange={handleChange} />
                                                                {errors.transport_kms && <span className="text-danger">{errors.transport_kms}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="transport_total_kms" className="form-label">Total Transport Fee<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="transport_total_kms" id="transport_total_kms" placeholder="Total Transport Fee" value={formData.transport_total_kms} onChange={handleChange} readOnly />
                                                                {errors.transport_total_kms && <span className="text-danger">{errors.transport_total_kms}</span>}
                                                            </div>
                                                        </div>


                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="total_fee" className="form-label">Total Fee<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="number" name="total_fee" id="total_fee" placeholder="Total Fee" value={formData.total_fee} onChange={handleChange} readOnly />
                                                                {errors.total_fee && <span className="text-danger">{errors.total_fee}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                            <div className="input-group">
                                                                <label htmlFor="total_fee_in_words" className="form-label">Total Fee In Words<span className="text-danger">*</span></label>
                                                                <input autoComplete="off" type="text" name="total_fee_in_words" id="total_fee_in_words" placeholder="Total Fee In Words" value={formData.total_fee_in_words} onChange={handleChange} />
                                                                {errors.total_fee_in_words && <span className="text-danger">{errors.total_fee_in_words}</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-5">
                                                    <h6><u>Student Background Details</u></h6>
                                                </div>


                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="father_edu_qualification" className="form-label">Father Edu Qualification<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="father_edu_qualification" id="father_edu_qualification" placeholder="Father Edu Qualification" value={formData.father_edu_qualification} onChange={handleChange} />
                                                        {errors.father_edu_qualification && <span className="text-danger">{errors.father_edu_qualification}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="father_occupation" className="form-label">Father Occupation<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="father_occupation" id="father_occupation" placeholder="Father Occupation" value={formData.father_occupation} onChange={handleChange} />
                                                        {errors.father_occupation && <span className="text-danger">{errors.father_occupation}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="father_annual_income" className="form-label">Father Annual Income<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="father_annual_income" id="father_annual_income" placeholder="Father Annual Income" value={formData.father_annual_income} onChange={handleChange} />
                                                        {errors.father_annual_income && <span className="text-danger">{errors.father_annual_income}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="mother_edu_qualification" className="form-label">Mother Edu Qualification <span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="mother_edu_qualification" id="mother_edu_qualification" placeholder="Mother Edu Qualification " value={formData.mother_edu_qualification} onChange={handleChange} />
                                                        {errors.mother_edu_qualification && <span className="text-danger">{errors.mother_edu_qualification}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="mother_occupation" className="form-label">Mother Occupation<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="mother_occupation" id="mother_occupation" placeholder="Mother Occupation" value={formData.mother_occupation} onChange={handleChange} />
                                                        {errors.mother_occupation && <span className="text-danger">{errors.mother_occupation}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="mother_annual_income" className="form-label">Mother Annual Income<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="mother_annual_income" id="mother_annual_income" placeholder="Mother Annual Income" value={formData.mother_annual_income} onChange={handleChange} />
                                                        {errors.mother_annual_income && <span className="text-danger">{errors.mother_annual_income}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="guardian_edu_qualification" className="form-label">Guardian Edu Qualification<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="guardian_edu_qualification" id="guardian_edu_qualification" placeholder="Guardian Edu Qualification" value={formData.guardian_edu_qualification} onChange={handleChange} />
                                                        {errors.guardian_edu_qualification && <span className="text-danger">{errors.guardian_edu_qualification}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="guardian_occupation" className="form-label">Guardian Occupation<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="guardian_occupation" id="guardian_occupation" placeholder="Guardian Occupation" value={formData.guardian_occupation} onChange={handleChange} />
                                                        {errors.guardian_occupation && <span className="text-danger">{errors.guardian_occupation}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="guardian_annual_income" className="form-label">Guardian Annual Income<span className="text-danger">*</span></label>
                                                        <input autoComplete="off" type="text" name="guardian_annual_income" id="guardian_annual_income" placeholder="Guardian Annual Income" value={formData.guardian_annual_income} onChange={handleChange} />
                                                        {errors.guardian_annual_income && <span className="text-danger">{errors.guardian_annual_income}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="personal_identification_of_student_marks" className="form-label">Personal Identification of Student Marks<span className="text-danger">*</span></label>
                                                        <textarea name="personal_identification_of_student_marks" id="personal_identification_of_student_marks" value={formData.personal_identification_of_student_marks} onChange={handleChange}></textarea>
                                                        {errors.personal_identification_of_student_marks && <span className="text-danger">{errors.personal_identification_of_student_marks}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <div className="row">
                                                        <div className="col-6 py-3 px-0">
                                                            <div className="body p-0">
                                                                <div className="table-responsive">
                                                                    <table className="table table-hover m-0">
                                                                        <thead>
                                                                            <tr className="text-center tableHead">
                                                                                <th>year</th>
                                                                                <th>class</th>
                                                                                <th>name_of_school</th>
                                                                                <th>place</th>
                                                                                <th>medium_instruction</th>
                                                                                <th>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {previousEducation.length > 0 ? (
                                                                                previousEducation.map((data, index) => (
                                                                                    <tr key={index}>
                                                                                        <td className="text-center d-none">{index + 1}</td>
                                                                                        <td className="text-center">{data.year}</td>
                                                                                        <td className="text-center">{data.prev_class}</td>
                                                                                        <td className="text-center">{data.name_of_school}</td>
                                                                                        <td className="text-center">{data.place}</td>
                                                                                        <td className="text-center">{data.medium_instruction}</td>
                                                                                        <td className="text-center">
                                                                                            <i className="fa-solid fa-pen me-3 text-success" onClick={() => getPreviousEducationData(index)} ></i>
                                                                                            <i className="fa-solid fa-trash text-danger" onClick={() => deletePreviousEducationData(index)}></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))
                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan="7" className="text-center">No Data Found</td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                                    <div className="input-group">
                                                                        <label htmlFor="year" className="form-label">Year<span className="text-danger">*</span></label>
                                                                        <input autoComplete="off" type="text" name="year" id="year" placeholder="Year" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                                    <div className="input-group">
                                                                        <label htmlFor="class" className="form-label">Class<span className="text-danger">*</span></label>
                                                                        <input autoComplete="off" type="text" name="class" id="prev_class" placeholder="Class" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                                    <div className="input-group">
                                                                        <label htmlFor="name_of_school" className="form-label">Name Of School<span className="text-danger">*</span></label>
                                                                        <input autoComplete="off" type="text" name="name_of_school" id="name_of_school" placeholder="Name Of School" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                                    <div className="input-group">
                                                                        <label htmlFor="place" className="form-label">Place<span className="text-danger">*</span></label>
                                                                        <input autoComplete="off" type="text" name="place" id="place" placeholder="Place" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                                    <div className="input-group">
                                                                        <label htmlFor="medium_instruction" className="form-label">Medium Of Instruction<span className="text-danger">*</span></label>
                                                                        <input autoComplete="off" type="text" name="medium_instruction" id="medium_instruction" placeholder="Medium Of Instruction" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-3 text-center">
                                                                    <button className='allBtns w-100' onClick={handlePreviouEducationForm} type='button'>Add</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 mt-5">
                                                    <h6><u>Declaration Details</u></h6>
                                                </div>

                                                <div className="col-lg-3 col-md-3 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="bro_sis_are_studying" className="form-label">Bro Sis Are Studying<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div>
                                                        <span className=''><input type="radio" className='allCheckBoxes me-1' name="bro_sis_are_studying" value="true" checked={formData.bro_sis_are_studying === "true"} onChange={handleChange} />Yes</span>
                                                        <span className='ms-5'><input type="radio" className='allCheckBoxes me-1' name="bro_sis_are_studying" value="false" checked={formData.bro_sis_are_studying === "false"} onChange={handleChange} />No</span>
                                                    </div>
                                                    {errors.bro_sis_are_studying && <span className="text-danger">{errors.bro_sis_are_studying}</span>}
                                                </div>

                                                <div className="col-lg-3 col-md-3 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="parent_are_employed_in_spr" className="form-label">Parent Are Employed in SPR<span className="text-danger">*</span></label>
                                                    </div>
                                                    <div>
                                                        <span className=''><input type="radio" className='allCheckBoxes me-1' name="parent_are_employed_in_spr" value="true" checked={formData.parent_are_employed_in_spr === "true"} onChange={handleChange} />Yes</span>
                                                        <span className='ms-5'><input type="radio" className='allCheckBoxes me-1' name="parent_are_employed_in_spr" value="false" checked={formData.parent_are_employed_in_spr === "false"} onChange={handleChange} />No</span>
                                                    </div>
                                                    {errors.parent_are_employed_in_spr && <span className="text-danger">{errors.parent_are_employed_in_spr}</span>}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="parent_emp_id" className="form-label">Parent Emp Id<span className="text-danger">*</span></label>
                                                        <input type="text" name="parent_emp_id" id="parent_emp_id" placeholder='Parent Emp Id' value={formData.parent_emp_id} onChange={handleChange} />
                                                    </div>
                                                </div>


                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 pt-3">
                                                    <div className="input-group">
                                                        <label htmlFor="decleration" className="form-label"><input type="checkbox" className='allCheckBoxes me-1' name="decleration" value={formData.decleration} onChange={handleChange} /> Yes, I Declare the above information is true<span className="text-danger">*</span></label>
                                                    </div>
                                                    {errors.decleration && <span className="text-danger">{errors.decleration}</span>}
                                                </div>
                                                <div className="col-12 my-5 text-center">
                                                    <button type="submit" className="allBtns w-100">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default List
