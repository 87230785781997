import React, { useEffect, useState } from 'react'
import sprLogo from '../assets/images/sprLogo.png';
import { postApi } from "../utilits/helpers/apiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { CheckLogin } from '../utilits/helpers/ChechLogin';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const { checkAuth } = CheckLogin();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({})

    useEffect(() => {
        checkAuth();
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId');

        if (token && userId) {
            navigate('/');
        }
    }, [])

    const postData = async (data) => {
        try {
            const response = await postApi("/account/login", data);
            setFormData({
                email: "",
                password: "",
            });
            let successMessage = "Login Successful";
            toast.success(successMessage);
            const token = response.response.token;
            const userId = response.response.user._id;
            const userData = response.response.user;
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("userId", userId);
            localStorage.setItem("token", token);
            window.location.href = "/";
        } catch (error) {
            console.log("Error caught:", error);
            let errorMessage = "An error occurred";
            if (error.response) {
                // Extracting the specific message from the server response
                if (error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                } else if (typeof error.response.data === "string") {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
                }
            } else if (error.request) {
                errorMessage = "No response received from server";
            } else {
                errorMessage = error.message;
            }

            toast.error(errorMessage);
        }
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        setErrors({
          ...errors,
          [name]: "",
        });
      };


    const loginSubmitHandler = (e) => {
        e.preventDefault();
        // console.log('Form Data:', formData);
        const validationErrors = validate(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            postData(formData);
            console.log('Form Data:', formData);
        }
    }

    const validate = (data) => {
        const errors = {};
        if (!data.email) errors.email = "Please Enter Email or Phone number";
        if (!data.password) errors.password = "Please Enter the Password";
        return errors;
    };

    return (
        <>
            <section className="p-0 m-0 loginSec">
                <ToastContainer />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center justify-content-center">
                            <div className='text-center'>
                                <img src={sprLogo} alt="sprLogo" />
                                <h6>OUR VISION</h6>
                                <p>
                                    "Empowering students to be tomorrow's leaders with a foundation of moral integrity and academic excellence.
                                    Nurturing students into individuals who will positively impact society globally.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 rightCol">
                            <div className="card">
                                <div className="container">
                                    <form id='loginForm' name='loginForm' onSubmit={loginSubmitHandler}>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <h4>Login</h4>
                                            </div>
                                            <div className="col-12 mt-5">
                                                <div className="input-group">
                                                    <label htmlFor="email" className="form-label">Email Or Phone Number<span className="text-danger">*</span>
                                                    </label>
                                                    <input autoComplete="off" type="text" name="email" id="email" placeholder="Email Or Phone Number" onChange={handleChange} value={formData.email} />
                                                    {errors.email && <p className="text-danger">{errors.email}</p>}
                                                </div>
                                            </div>
                                            <div className="col-12 mt-3">
                                                <div className="input-group">
                                                    <label htmlFor="password" className="form-label">Password<span className="text-danger">*</span>
                                                    </label>
                                                    <input autoComplete="off" type="password" name="password" id="password" placeholder="Password" onChange={handleChange} value={formData.password} />
                                                    {errors.password && <p className="text-danger">{errors.password}</p>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type='submit' className="allBtns w-100 mt-3">Login</button>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login
