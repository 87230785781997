// import React, { useEffect, useState } from 'react';
// import { getApi } from '../../utilits/helpers/apiService';
// import * as XLSX from 'xlsx';
// import FileDownloadImg from '../../assets/images/file.png';
// import ReactPaginate from 'react-paginate';

// const Admission = () => {
//   const [contactData, setContactData] = useState([]);
//   const [xlData, setXlData] = useState([]);

//   const [itemOffset, setItemOffset] = useState(0);
//   const itemsPerPage = 10;

//   const getContactReqs = async () => {
//     try {
//       const response = await getApi("/contact/get");
//       setContactData(response.response);
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   useEffect(() => {
//     getContactReqs();
//   }, [])

//   useEffect(() => {
//     const contactXlPrepare = contactData.map((contact, index) => ({
//       S_No: index + 1,
//       first_name: contact.first_name,
//       second_name: contact.second_name,
//       email: contact.email,
//       message: contact.message,
//       mobile_number: contact.mobile_number,
//       Contact_Date: new Date(contact.created_at).toLocaleDateString('en-GB')
//     }));
//     setXlData(contactXlPrepare);
//   }, [contactData]);

//   const downloadExcel = () => {
//     const worksheet = XLSX.utils.json_to_sheet(xlData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Contact Requests");
//     XLSX.writeFile(workbook, "Contact_Requests.xlsx");
//   }

//   const handlePageClick = (event) => {
//     const newOffset = (event.selected * itemsPerPage) % contactData.length;
//     setItemOffset(newOffset);
//   };

//   const endOffset = itemOffset + itemsPerPage;
//   const currentItems = xlData.slice(itemOffset, endOffset);
//   const pageCount = Math.ceil(xlData.length / itemsPerPage);

//   return (
//     <section>
//       <div className="container">
//         <div className="row">
//           <div className="col-xl-12 col-lg-12 mt-5">
//             <div className="card p-0">
//               <div className="container">
//                 <div className="row ">
//                   <div className="col-10 stripBg p-3">
//                     <h5>Contact Reqs</h5>
//                   </div>
//                   <div className="col-2 stripBg p-3 text-end">
//                     <button onClick={downloadExcel} className="allBtns">
//                       <img src={FileDownloadImg} width="25px" alt="" />
//                     </button>
//                   </div>
//                   <div className="col-12 my-3">
//                     <div className="row">
//                       <div className="col-12 my-3">
//                         <div className="row">
//                           <div className="col-12 py-3 px-0">
//                             <div className="body p-0">
//                               <div className="table-responsive">
//                                 <table className="table table-hover m-0">
//                                   <thead>
//                                     <tr className="text-center tableHead">
//                                       <th>S.No</th>
//                                       <th>First Name</th>
//                                       <th>Last Name</th>
//                                       <th>Mobile Number</th>
//                                       <th>Email</th>
//                                       <th>Message</th>
//                                       <th>Contact Date</th>
//                                     </tr>
//                                   </thead>
//                                   <tbody>
//                                     {currentItems.map((item, index) => (
//                                       <tr key={index}>
//                                         <td className="text-center">{item.S_No}</td>
//                                         <td className="text-center">{item.first_name}</td>
//                                         <td className="text-center">{item.second_name}</td>
//                                         <td className="text-center">{item.mobile_number}</td>
//                                         <td className="text-center">{item.email}</td>
//                                         <td className="text-center">{item.message}</td>
//                                         <td className="text-center">{item.Contact_Date}</td>
//                                       </tr>
//                                     ))}
//                                   </tbody>
//                                 </table>
//                                 <ReactPaginate
//                                   breakLabel="..."
//                                   nextLabel=">"
//                                   onPageChange={handlePageClick}
//                                   pageRangeDisplayed={4}
//                                   pageCount={pageCount}
//                                   previousLabel="<"
//                                   renderOnZeroPageCount={null}
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default Admission;




import React, { useEffect, useState } from 'react';
import { getApi } from '../../utilits/helpers/apiService';
import * as XLSX from 'xlsx';
import FileDownloadImg from '../../assets/images/file.png';
import ReactPaginate from 'react-paginate';

const Admission = () => {
  const [admissions, setAdmissions] = useState([]);
  const [xlData, setXlData] = useState([]);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const getContactReqs = async (student_id = null, page = 1, limit = 10) => {
    try {
      let url = `/admission/students?page=${page}&limit=${limit}`;
      const response = await getApi(url);
      console.log(response.response);
      setRecordsPerPage(response.response.recordsPerPage);
      setTotalPages(response.response.totalPages);
      setAdmissions(response.response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContactReqs(null, page, recordsPerPage);
  }, [page, recordsPerPage]);

  useEffect(() => {
    const contactXlPrepare = admissions.map((contact, index) => ({
      S_No: index + 1 + (page - 1) * recordsPerPage,
      first_name: contact.first_name,
      second_name: contact.second_name,
      email: contact.email,
      message: contact.message,
      mobile_number: contact.mobile_number,
      Contact_Date: new Date(contact.created_at).toLocaleDateString('en-GB'),
    }));
    setXlData(contactXlPrepare);
  }, [admissions, page, recordsPerPage]);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(xlData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contact Requests");
    XLSX.writeFile(workbook, "Contact_Requests.xlsx");
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-lg-12 mt-5">
            <div className="card p-0">
              <div className="container">
                <div className="row">
                  <div className="col-10 stripBg p-3">
                    <h5>Contact Reqs</h5>
                  </div>
                  <div className="col-2 stripBg p-3 text-end">
                    <button onClick={downloadExcel} className="allBtns">
                      <img src={FileDownloadImg} width="25px" alt="" />
                    </button>
                  </div>
                  <div className="col-12 my-3">
                    <div className="row">
                      <div className="col-12 my-3">
                        <div className="row">
                          <div className="col-12 py-3 px-0">
                            <div className="body p-0">
                              <div className="table-responsive">
                                <table className="table table-hover m-0">
                                  <thead>
                                    <tr className="text-center tableHead">
                                      <th>S.No</th>
                                      <th>First Name</th>
                                      <th>Last Name</th>
                                      <th>Mobile Number</th>
                                      <th>Email</th>
                                      <th>Message</th>
                                      <th>Contact Date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {admissions.map((item, index) => (
                                      <tr key={index}>
                                        <td className="text-center">
                                          {item.S_No}
                                        </td>
                                        <td className="text-center">
                                          {item.student_fullname}
                                        </td>
                                        <td className="text-center">
                                          {item.class_id.class}
                                        </td>
                                        <td className="text-center">
                                          {item.mobile_number}
                                        </td>
                                        <td className="text-center">
                                          {item.email}
                                        </td>
                                        <td className="text-center">
                                          {item.message}
                                        </td>
                                        <td className="text-center">
                                          {item.Contact_Date}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                <ReactPaginate
                                  breakLabel="..."
                                  nextLabel=">"
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={5}
                                  pageCount={totalPages}
                                  previousLabel="<"
                                  renderOnZeroPageCount={null}
                                  containerClassName="pagination"
                                  activeClassName="active"
                                  pageClassName="page-item"
                                  pageLinkClassName="page-link"
                                  previousClassName="page-item"
                                  previousLinkClassName="page-link"
                                  nextClassName="page-item"
                                  nextLinkClassName="page-link"
                                  breakClassName="page-item"
                                  breakLinkClassName="page-link"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Admission;
