import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import sprLogo from '../../assets/images/sprLogo.png';
import { getApi } from '../../utilits/helpers/apiService';
import SprStrip from '../../assets/images/SprStrip.png';
const PrintBill = () => {
    const { checkAuth } = CheckLogin();
    const location = useLocation();
    const { feeData } = location.state || {};
    const [studentFeeNeedToPaid, setStudentFeeNeedToPaid] = useState({})

    useEffect(() => {
        checkAuth();
        fetchData(feeData?.student_id?._id);
    }, []);

    const fetchData = async (student_id = null) => {
        try {
            let url = "/fee-collect/get"
            if (student_id) {
                url += `?student_id=${student_id}`
            }
            const response = await getApi(url);
            console.log(response);

            if (student_id) {
                const feeObj = {
                    admission_balance: response?.response?.totalFee?.totalAdmissionFee - response?.response?.totalFeeCollected?.totalAdmissionFee,
                    tution_balance: response?.response?.totalFee?.totalNetTutionFee - response?.response?.totalFeeCollected?.totalNetTutionFee,
                    transport_balance: response?.response?.totalFee?.totalTransportationAmount - response?.response?.totalFeeCollected?.totalTransportationAmount,
                }
                setStudentFeeNeedToPaid(feeObj)
            }
        } catch (error) {
            console.log(error);
        }
    };

    if (!feeData) {
        return (
            <section className='d-flex align-items-center justify-content-center'>
                <div className="row">
                    <div className="col-12 ">
                        <h6>No Data Found !!!!</h6>
                    </div>
                </div>
            </section>
        )
    }

    const presentDate = new Date();
    const feeDate = new Date(feeData.fee_date);
    const formattedDate = feeDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

    const dueDate = new Date(feeData.dueDate);
    const formattedDueDate = dueDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

    const handlePrint = () => {
        window.print();
    };
    return (
        <>
            <style>
                {`
                    @media print {
                        .print-hidden {
                            display: none;
                        }
                        .no-print {
                            display: none;
                        }
                    }
                `}
            </style>

            <section className='printBillSec p-0 m-0'>
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src={SprStrip} width="50%" height="50px" alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="row mt-2">
                                        <div className="col-lg-6">
                                        </div>
                                        <div className="col-lg-6 text-end">
                                            <h6>Date: <u className='ms-2'>{presentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</u></h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-2">
                                            <h6>Student Name : <span className='text-dark'>{feeData.student_id?.student_fullname} </span> </h6>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <h6>Student ID : <span className='text-dark'>{feeData.student_id?.student_id}</span> </h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr className='m-1' />
                                        
                                        <div className="col-6">
                                            <h6>Fee Paid Date : <span className='text-dark'>{formattedDate}</span> </h6>
                                        </div>
                                        <div className="col-6">
                                            <h6>Class : <span className='text-dark'>{feeData?.class_id?.class}-{feeData?.class_id?.section}</span> </h6>
                                        </div>
                                        <hr className='m-1' />

                                        <table className="table text-center mx-3">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>S.NO</th>
                                                    <th>PARTICULAR</th>
                                                    <th>AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Admission Fee</td>
                                                    <td>{feeData.admission_fee}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Tution Fee</td>
                                                    <td>{feeData.tution_fee}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Tution Fee</td>
                                                    <td>{feeData.transport_fee}/-</td>
                                                </tr>
                                                <tr className='mt-4'>
                                                    <td colspan="2" className='text-center'><h6>TOTAL AMOUNT</h6></td>
                                                    <td><h6>{feeData.total_fee_paid}/-</h6></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-12">
                                                <h6 className=''>Next Due Date : <u className='ms-2'>{formattedDueDate}</u></h6>
                                            </div>
                                        </div>
                                        <div className='row mt-1'>
                                            <h6>Penindg Fee</h6>
                                            <div className="col-4">Admission: {studentFeeNeedToPaid?.admission_balance}</div>
                                            <div className="col-4">Tutuion: {studentFeeNeedToPaid?.tution_balance}</div>
                                            <div className="col-4">Transport: {studentFeeNeedToPaid?.transport_balance}</div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 text-end">
                                                <p className=''>For SRI SPR GLOBAL SCHOOL</p>
                                            </div>
                                            <div className="col-12 text-end mt-3 pt-2">
                                                <p className=''>Signature</p>
                                            </div>
                                        </div>

                                        <div className="row print-hidden">
                                            <div className="col-6 text-end">
                                                <button className='allBtns' onClick={handlePrint}>Print</button>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/student-fee-collection"><button className='allBtns'>Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrintBill
