import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import sprLogo from '../../assets/images/sprLogo.png';
import { getApi } from '../../utilits/helpers/apiService';
import SprStrip from '../../assets/images/SprStrip.png';

const AdmissionPrintBill = () => {
    const { checkAuth } = CheckLogin();
    const location = useLocation();
    const { admissionData } = location.state || {};
    const [studentAdmissionFee, setStudentAdmissionFee] = useState({})

    useEffect(() => {
        checkAuth();
        console.log(admissionData)
        fetchData(admissionData);
    }, []);

    const fetchData = async (admissionData) => {
        
        try {
            const netTutionFee = await getApi(`/feessturcture/get/${admissionData.net_tution_fee}`);
            const admissionFee = await getApi(`/feessturcture/get/${admissionData.admission_fee}`);

            console.log("bbcbcb", netTutionFee.response.foundApplicant.fee_amount)

            const feeObj = {
                admissionFee: admissionFee.response.foundApplicant.fee_amount,
                netTutionFee: netTutionFee.response.foundApplicant.fee_amount
            }
            setStudentAdmissionFee(feeObj);
        } catch (error) {
            console.log(error);
        }
    };
    if (!admissionData) {
        return (
            <section className='d-flex align-items-center justify-content-center'>
                <div className="row">
                    <div className="col-12 ">
                        <h6>No Data Found !!!!</h6>
                    </div>
                </div>
            </section>
        )
    }

    const presentDate = new Date();
    const dob = new Date(admissionData.dob);
    const joingDate = new Date(admissionData.joining_date);
    const joingDateFormated = joingDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedDate = dob.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' });

    const handlePrint = () => {
        window.print();
    };
    return (
        <>
            <style>
                {`
                    @media print {
                        .print-hidden {
                            display: none;
                        }
                        .no-print {
                            display: none;
                        }
                    }
                `}
            </style>

            <section className='printBillSec p-0 m-0'>
                <div className="container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-12 text-center">
                                    <img src={SprStrip} width="60%" height="100px" alt="" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row mt-2">
                                        <div className="col-lg-6">
                                        </div>
                                        <div className="col-lg-6 text-end">
                                            <h6>Date: <u className='ms-2'>{presentDate.toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</u></h6>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6  mt-3">
                                            <h6>Student Name : <span className='text-dark'>{admissionData.student_fullname} </span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>Father Name : <span className='text-dark'>{admissionData.father_name}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>Mother Name : <span className='text-dark'>{admissionData.mother_name}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Student ID : <span className='text-dark'>{admissionData.student_id}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>Joing Date : <span className='text-dark'>{joingDateFormated} </span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>Payment Type : <span className='text-dark'>{admissionData.payment_type}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>First Language : <span className='text-dark'>{admissionData.first_language}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3 ">
                                            <h6>Class : <span className='text-dark'>{admissionData.class_id.class} - {admissionData.class_id.section}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Gender : <span className='text-dark'>{admissionData.gender}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Religion : <span className='text-dark'>{admissionData.religion}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Caste : <span className='text-dark'>{admissionData.caste}</span> </h6>
                                        </div>

                                        <div className="col-6  mt-3">
                                            <h6>DoB : <span className='text-dark'>{formattedDate}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Tc.No : <span className='text-dark'>{admissionData.tc_no}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>St.Aadhar No : <span className='text-dark'>{admissionData.student_aadhar_no}</span> </h6>
                                        </div>
                                        <div className="col-6  mt-3">
                                            <h6>Email : <span className='text-dark'>{admissionData.email}</span> </h6>
                                        </div>
                                        
                                        
                                        <div className="col-6  mt-3">
                                            <h6>Father P.no : <span className='text-dark'>{admissionData.father_mobile_no}</span> </h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <hr className='m-3' />
                                        <table className="table text-center mx-3">
                                            <thead>
                                                <tr className="text-center">
                                                    <th>S.NO</th>
                                                    <th>PARTICULAR</th>
                                                    <th>AMOUNT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Admission Fee</td>
                                                    <td>{studentAdmissionFee?.admissionFee}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Tution Fee</td>
                                                    <td>{studentAdmissionFee.netTutionFee}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Discount Fee</td>
                                                    <td>{admissionData?.discount_fee ? admissionData.discount_fee : 0}/-</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>Total Transport Free</td>
                                                    <td>{admissionData.transport_total_kms}/-</td>
                                                </tr>
                                                <tr className='mt-4'>
                                                    <td colspan="2" className='text-center'><h6>TOTAL AMOUNT</h6></td>
                                                    <td><h6>{admissionData.total_fee}/-</h6></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                         
                                        <div className="row mt-3">
                                            <div className="col-12 text-end">
                                                <p className=''>For SRI SPR GLOBAL SCHOOL</p>
                                            </div>
                                            <div className="col-12 text-end mt-5 pt-2">
                                                <p className=''>Signature</p>
                                            </div>
                                        </div>

                                        <div className="row print-hidden">
                                            <div className="col-6 text-end">
                                                <button className='allBtns' onClick={handlePrint}>Print</button>
                                            </div>
                                            <div className="col-6">
                                                <Link to="/admissions"><button className='allBtns'>Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AdmissionPrintBill
