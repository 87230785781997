import React, { useState, useEffect } from "react";
import {

  getApi,
  postApi,
} from "../../utilits/helpers/apiService";
import { CheckLogin } from '../../utilits/helpers/ChechLogin';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Typeahead } from "react-bootstrap-typeahead";

export default function StudentAttendence() {
  const { checkAuth } = CheckLogin();
  const [studentData, setStudentData] = useState([]);
  const [attendenceData, setAttendenceData] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState("");
  const [attendDate, setAttendDate] = useState(new Date().toLocaleDateString('en-US'));
  const [filterDate, setFilterDate] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [markAll, setMarkAll] = useState(false);


  const fetchClassList = async () => {
    try {
        const url = '/class/get'
        const response = await getApi(url);
        console.log(response);
        setClassList([...response.response]);
    } catch (error) {
        console.log(error);
    }
}

  const fetchData = async () => {
    try {
      let url = '/student_attendence/get'

      if (filterDate) {
          url = url + `?start_date=${filterDate}&end_date=${filterDate}`
      }
      const response = await getApi(url);

      setAttendenceData([...response.response.data]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClassData = async (class_id) =>{
    try {
      const url = `/student_attendence/studets-class-wise?class_id=${class_id ? class_id : classId}`
      const response = await getApi(url);
      setStudentData([...response.response]);
    } catch (error) {
      console.log(error);
    }
  }

  
  useEffect(() => {
    checkAuth();
    fetchClassList();
    filterAttendence();
  }, []);

  const postData = async (data) => {
    try {
      const response = await postApi("/student_attendence/create", data);
      let successMessage = "User created successfully";
      toast.success(successMessage);
      fetchData();
      console.log(response);
    } catch (error) {
      
      let errorMessage = "An error occurred";
      if (error.response) {
        // Extracting the specific message from the server response
        if (error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data;
        } else {
          errorMessage = `Error: ${error.response.status} - ${error.response.statusText}`;
        }
      } else if (error.request) {
        errorMessage = "No response received from server";
      } else {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    }
  };
  
  const submitStudent = (e) => {
    e.preventDefault();
    const payload = []
    studentData.map((value) => {
      payload.push({
        student_id: value?.student_id?._id,
        is_present: value.is_present,
        class_id: classId,
        attend_date: attendDate
      })
    });    
    postData(payload);
    setIsAdd(false);
  };




  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    const studentArray = [...studentData];
    studentArray[index]['is_present'] = checked ? true : false;
    setStudentData(studentArray);
  };

  const onClassChange = (e) => {
    setClassId(e.target.value);
    fetchClassData(e.target.value);
  };

  const filterAttendence = () => {
    fetchData()
  };

  const onDateChange = (e) => {
    setAttendDate(e.target.value);
  }

  const editAttendence = (classSheet) => {
    setIsAdd(true);
    setClassId(classSheet._id);
    setAttendDate(new Date(classSheet?.students[0]?.attend_date).toISOString().split('T')[0]);
    setStudentData(classSheet?.students);
  }

  const deleteAttendence = (classSheet) => {
    console.log(classSheet);
  }

  const handleMarkAllChange = (e) => {
    const {checked} = e.target;
    setMarkAll(checked);
    const studentArray = [...studentData];
    studentArray.map((item) => {
      item['is_present'] = checked ? true : false;
      return item;
    });
    setStudentData(studentArray);
  };


  return (
    <>
      <section className="accountSec">
        <div className="container">
          <div>
            <ToastContainer />
          </div>

          <div className="row">
           {!isAdd && (<div className="col-xl-12 col-lg-12 mt-5">
              <div className="card p-0">
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                      <h5>VIEW ALL ATTENDENCES CLASS WISE</h5>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(!isAdd)}>{ isAdd ?  'Back' : 'Add'}</button>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-12 mt-3">
                      <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            Select Attendence Date<span className="text-danger">*</span>
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>
                          {/* new Date(addData.dob).toISOString().split('T')[0] */}
                          <input type="date" name="attendenceDate" id="attendenceDate" value={filterDate} onChange={e => setFilterDate(new Date(e.target.value).toISOString().split('T')[0])} />
                          
                          </div>


                          </div>
                    
                        </div>

                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-12 col-12 pt-3">
                        <button className="btn btn-primary mt-3" onClick={filterAttendence}>Filter Data</button>

                      </div>
                      </div>
                    </div>

                    <div className="col-12 py-3 px-0">
                      <div className="body p-0">
                        <div className="table-responsive">
                          <table className="table table-hover m-0">
                            <thead>
                              <tr className="text-center tableHead">
                                <th>S.No</th>
                                <th>Class</th>
                                <th>Total Students</th>
                                <th>Present</th>
                                <th>Absent</th>
                                <th>Attendence Date</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {attendenceData ? (
                                attendenceData.map((attendence, index) => (
                                  <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{attendence?.class}</td>
                                    <td>{attendence?.students?.length}</td>
                                    <td>{attendence?.present_count}</td>
                                    <td>{attendence?.absent_count}</td>
                                    <td>{new Date(attendence.attend_date).toLocaleDateString('en-US')}</td>
                                    <td>
                                        <i className="fa-solid fa-pen me-3 text-success" onClick={() => editAttendence(attendence)} ></i>
                                        <i className="fa-solid fa-trash text-danger" onClick={() => deleteAttendence(attendence)}></i>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                    No accounts found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            {
              isAdd && 
      (

            <div className="col-xl-12 col-lg-12 mt-5">
              <div className="card p-0">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-8 col-12 stripBg p-3">
                      <h5>UPDATEATTENDENCE INFORMATION</h5>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12  p-3 stripBg text-lg-end text-md-end text-sm-end">
                        <button className="hideAndShowBtns" onClick={() => setIsAdd(!isAdd)}>{ isAdd ?  'Back' : 'Add'}</button>
                    </div>
                  </div>
                  <form
                    name="StudentForm"
                    id="StudentForm"
                    onSubmit={submitStudent}
                  >
                    <div className="row px-2">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            Search Class<span className="text-danger">*</span>
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>

                          <select name="searchBy" id="searchBy" onChange={onClassChange} value={classId}>
                            <option value="">Select Class</option>
                            {
                              classList.map((item, index) => <option key={index} value={item._id}>{item.class}</option>)
                            }
                          </select>
                          </div>


                          </div>
                    
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-12 pt-3">
                        <div className="input-group">
                          <label className="form-label">
                            Select Attendence Date<span className="text-danger">*</span>
                          </label>
                          <div style={{display: "flex"}}>
                          <div style={{marginRight: 5}}>
                          
                          <input type="date" name="attendenceDate" id="attendenceDate" value={attendDate} onChange={onDateChange} />
                          
                          </div>


                          </div>
                    
                        </div>
                      </div>
                      <div className="row ">
                    
                    <div className="col-12 py-3 px-0">
                      <div className="body p-0">
                        <div className="table-responsive">
                          <table className="table table-hover m-0">
                            <thead>
                              <tr className="text-center tableHead">
                                <th>S.No</th>
                                <th>Student Name</th>
                                <th>Student ID</th>
                                <th>Present <input
                                        type="checkbox"
                                        name="present"
                                        id="present"
                                        checked={markAll}
                                        onChange={handleMarkAllChange}
                                      /></th>
                              </tr>
                            </thead>
                            <tbody>
                              {studentData ? (
                                studentData.map((attendence, index) => (
                                  <tr key={index} className="text-center">
                                    <td>{index + 1}</td>
                                    <td>{attendence?.student_id?.student_fullname}</td>
                                    <td>{attendence?.student_id?.student_id}</td>
                                    <td>
                                      <input
                                        type="checkbox"
                                        name="present"
                                        id="present"
                                        // value={attendence?.is_present}
                                        checked={attendence?.is_present}
                                        onChange={(e) => handleCheckboxChange(e, index)}
                                      />
                                    </td>
                                    
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="4" className="text-center">
                                    No accounts found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                      <div className="col-12 my-5 text-center">
                        <button type="submit" className="allBtns w-100">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
      )
            }
          </div>
        </div>
      </section>
    </>
  );
}
